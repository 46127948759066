import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Warning from 'common/layout/Warning'
import {
  BODY_FIELD_NAME,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_VIDEO,
  LEVELED_TEXT_BODY_FIELD_NAME,
  SPANISH_BODY_FIELD_NAME,
  TEACHER_EDITION_PREFIX,
} from 'core/consts'
import { omit } from 'fp/objects'
import useComponentSize from 'hooks/useComponentSize'
import { builderContext } from 'hss/ContentBuilder/interactives/Form/builderContext'
import PropTypes from 'prop-types'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { isDevEnv, isPreviewEnv } from 'selectors/index'
import ContentTypeTabs from './ContentTypeTabs'
import EnhancedRichTextEdit, { allFeatures } from './EnhancedRichTextEdit'

/**
 * Ideally these would be calculated directly by inspecting the actual toolbar
 * height, however there doesn't seem to be a way to keep dom node references
 * when passed through a lazy-loaded component.
 *
 * These then are hardcoded widths for when the toolbar or action bar wraps.
 * Update the former if we add more buttons to the toolbar!
 */
const magicToolbarWidthNumber = 710
const magicButtonWidthNumber = 350

const propsToRemainHidden = {
  'aria-hidden': true,
  style: { display: 'none' },
}

const ContentPanel = props => {
  const {
    currentContentType,
    debugVisible,
    hasChapterSummaryAncestor,
    hasTeAncestor,
    parentIsPage,
    toggleDebugVisible,
  } = props

  const isDev = useSelector(isDevEnv)
  const isStaging = useSelector(isPreviewEnv)
  const { currentBodyType } = useContext(builderContext)

  const [editorHeight, setEditorHeight] = useState(0)
  const [maxHeight, setMaxHeight] = useState('100vh')
  const [editorTop, setEditorTop] = useState(100)

  const ref = useRef()
  const infoRef = useRef()
  const { width: editorWidth } = useComponentSize(ref)

  const contentBlockVariant = useWatch({ name: 'variant' })

  const showTENotice =
    contentBlockVariant === 'chapter-recap' && currentContentType === 'te'

  const { top: toolbarTop } = ref.current?.getBoundingClientRect?.() ?? 0
  const { height: infoHeight } =
    infoRef.current?.getBoundingClientRect?.() ?? {}

  const features = omit(
    contentBlockVariant === 'chapter-recap'
      ? ['tables', 'typography.format']
      : [],
  )(allFeatures)

  const allowedInteractives =
    hasChapterSummaryAncestor || parentIsPage
      ? [
          // Limiting to non-scoreable interactives when contentType is `page`. Also
          // removing some interactives that make no sense on pages, or that would
          // cause recursion.
          //
          // The same applies to chapter summaries
          INTERACTIVE_TYPE_ANIMATOR,
          INTERACTIVE_TYPE_AUDIO,
          INTERACTIVE_TYPE_CHART,
          INTERACTIVE_TYPE_IMAGE_ZOOMY,
          INTERACTIVE_TYPE_IMAGE,
          INTERACTIVE_TYPE_VIDEO,
        ]
      : contentBlockVariant === 'chapter-recap'
        ? [
            // Chapter recap blocks can only contain a narrow list of interactive types.
            INTERACTIVE_TYPE_IMAGE,
          ]
        : [
            // An empty array signifies that all interactive types are permitted.
            // To disallow ALL interactives, return the string "none" instead of an
            // array.
          ]

  useEffect(() => {
    setMaxHeight(debugVisible ? '18vh' : '1000px')
    let newHeight =
      toolbarTop +
      200 +
      (editorWidth > magicToolbarWidthNumber ? -21 : 33) +
      (infoHeight ?? 0)
    if (editorWidth < magicButtonWidthNumber) {
      newHeight += 100
      setMaxHeight(debugVisible ? '23vh' : '1000px')
    }

    if (editorWidth > magicToolbarWidthNumber) {
      setMaxHeight(debugVisible ? '20vh' : '1000px')
    }

    setEditorHeight(`calc(100vh - ${newHeight}px)`)
    setEditorTop(newHeight - 400)
  }, [editorWidth, debugVisible, toolbarTop, infoHeight])

  const containerProps = useMemo(
    () => ({
      sx: {
        '.DraftEditor-editorContainer, .public-DraftEditor-content': {
          height: '100%',
        },
        '.DraftEditor-root': {
          height: editorHeight,
        },
      },
    }),
    [editorHeight],
  )

  return (
    <Box
      ref={ref}
      {...containerProps}>
      {Boolean(showTENotice) && (
        <Warning
          bgcolor="error.light"
          ref={infoRef}
          variant="contained">
          <strong>NOTICE:</strong> Only TE content from the first 'Row Block' in
          each grouping is displayed
        </Warning>
      )}

      <ContentTypeTabs {...{ editorTop, hasTeAncestor }} />

      <EnhancedRichTextEdit
        allowedInteractives={allowedInteractives}
        contentBlockVariant={contentBlockVariant}
        excludeToolButtons={['Answer']}
        features={features}
        label=""
        maxHeight={maxHeight}
        name="body"
        {...(currentContentType === 'se' && currentBodyType === BODY_FIELD_NAME
          ? {}
          : propsToRemainHidden)}
      />

      <EnhancedRichTextEdit
        allowedInteractives={allowedInteractives}
        features={features}
        label=""
        maxHeight={maxHeight}
        name={`${TEACHER_EDITION_PREFIX}body`}
        {...(currentContentType === 'te' ? {} : propsToRemainHidden)}
      />

      <EnhancedRichTextEdit
        allowedInteractives={allowedInteractives}
        contentBlockVariant={contentBlockVariant}
        excludeToolButtons={['Answer']}
        features={features}
        label=""
        maxHeight={maxHeight}
        name={SPANISH_BODY_FIELD_NAME}
        {...(currentContentType === 'se' &&
        currentBodyType === SPANISH_BODY_FIELD_NAME
          ? {}
          : propsToRemainHidden)}
      />

      <EnhancedRichTextEdit
        allowedInteractives={allowedInteractives}
        contentBlockVariant={contentBlockVariant}
        excludeToolButtons={['Answer']}
        features={features}
        label=""
        maxHeight={maxHeight}
        name={LEVELED_TEXT_BODY_FIELD_NAME}
        {...(currentContentType === 'se' &&
        currentBodyType === LEVELED_TEXT_BODY_FIELD_NAME
          ? {}
          : propsToRemainHidden)}
      />

      {Boolean(isDev || isStaging) && (
        <Button
          onClick={() => toggleDebugVisible()}
          style={{
            float: 'left',
            padding: 0,
            textDecoration: 'underline',
            opacity: 0.5,
          }}
          variant="tertiary">
          toggle debugger
        </Button>
      )}
    </Box>
  )
}

ContentPanel.propTypes = {
  currentContentType: PropTypes.oneOf(['se', 'te']).isRequired,
  debugVisible: PropTypes.bool.isRequired,
  hasChapterSummaryAncestor: PropTypes.bool.isRequired,
  hasTeAncestor: PropTypes.bool.isRequired,
  parentIsPage: PropTypes.bool.isRequired,
  toggleDebugVisible: PropTypes.func.isRequired,
}

export default ContentPanel

import Button from '@mui/material/Button'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import { isEmpty } from 'fp/arrays'
import { titleCase } from 'fp/strings'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const MediaList = props => {
  const { uploadType } = props
  const { setValue } = useFormContext()
  const label = titleCase(uploadType)
  const uploadsMap = useWatch({ name: 'uploadsMap' })
  const uploads = useMemo(
    () => Object.keys(uploadsMap).filter(key => key.includes(uploadType)),
    [uploadType, uploadsMap],
  )
  const uploaders = isEmpty(uploads) ? [`${uploadType}0`] : uploads

  const handleAdd = () => {
    setValue(`uploadsMap.${uploadType}${uploaders.length}`, null)
  }

  useDeepCompareEffect(() => {
    const nonEmptyMedia = uploads.filter(upload => uploadsMap[upload] !== null)
    const renamed = nonEmptyMedia.reduce(
      (keyMap, key, idx) => ({
        ...keyMap,
        [`${uploadType}${idx}`]: uploadsMap[key],
        ...(`${uploadType}${idx}` === key ? {} : { [key]: null }),
      }),
      {},
    )
    for (const key of Object.keys(renamed)) {
      setValue(`uploadsMap.${key}`, renamed[key])
    }
  }, [setValue, uploadType, uploadsMap])
  return (
    <>
      {uploaders.map((key, idx) => (
        <HFAssetUploader
          key={key}
          label={`${label} ${idx + 1}`}
          name={`uploadsMap.${key}`}
          uploadType={uploadType}
        />
      ))}
      <Button
        disabled={uploaders.length > 9}
        onClick={handleAdd}
        size="small"
        sx={{ mt: 2 }}>
        Add
      </Button>
    </>
  )
}

MediaList.propTypes = {
  uploadType: PropTypes.string.isRequired,
}

export default MediaList

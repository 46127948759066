import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import HFDatePicker from 'common/formControls/dateAndTime/HFDatePicker'
import HFSelect from 'common/formControls/selects/HFSelect'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import TextField from 'common/formControls/textInputs/TextField'
import FormSection from 'common/layout/FormSection'
import Headline from 'common/text/Headline'
import { CONTENT_TYPE_VOCABULARY } from 'core/consts'
import { isDefined, unless } from 'fp/utils'
import withContent from 'hoc/withContent'
import Form from 'hss/ContentBuilder/interactives/Form'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import { useNavigate } from 'react-router-dom'
import { contentBuilderUrl } from 'routing/consts'
import List from './List'
import MediaList from './MediaList'
import VocabTermField from './VocabTermField'

const defaultValues = {
  contentSubType: 'content',
  testPrepQuestions: [],
  bullets: [],
  otherMatches: [],
  images: [],
  uploadsMap: {},
}
const types = ['content', 'academic', 'footnote']
const wordForms = ['noun', 'verb', 'adjective', 'adverb']

const StyledVocabSelect = styled(HFSelect, { name: 'Vocab-Select' })(() => ({
  minWidth: 140,
}))

const Vocabulary = formProps => {
  const { content } = formProps
  const editing = isDefined(content)
  const navigate = useNavigate()
  const onSuccess = ({ response: { id } }) => {
    unless(
      editing,
      navigate,
      `${contentBuilderUrl}/curriculum/${CONTENT_TYPE_VOCABULARY}/${id}`,
    )
  }

  return (
    <Form
      {...{
        defaultValues,
        formProps,
      }}
      assetCodeRequired={false}
      formProps={formProps}
      interactiveInstructionsAllowed={false}
      nameRequired
      omitAssetCode
      omitName
      onSuccess={onSuccess}
      studentInstructionsAllowed={false}
      tagsAllowed
      vocabPhrasesAllowed={false}>
      <Stack spacing={6}>
        <VocabTermField />
        <StyledVocabSelect
          label="Word Form"
          name="wordForm"
          required>
          {wordForms.map(wordForm => (
            <MenuItem
              key={wordForm}
              value={wordForm}>
              {wordForm}
            </MenuItem>
          ))}
        </StyledVocabSelect>
        <StyledVocabSelect
          label="Vocab Type"
          name="contentSubType">
          {types.map(type => (
            <MenuItem
              key={type}
              value={type}>
              {type}
            </MenuItem>
          ))}
        </StyledVocabSelect>
      </Stack>
      <HFRichTextEditLite name="definition" />
      <HFTextField name="pronunciation" />
      <FormSection title="Bullets">
        <List
          formProps={formProps}
          InputRenderer={TextField}
          label="Bullets"
          maximumItems={10}
          name="bullets"
        />
      </FormSection>
      <FormSection title="Geo Tag">
        <HFTextField
          label="Latitude"
          name="geotag.latitude"
        />
        <HFTextField
          label="Longitude"
          name="geotag.longitude"
        />
      </FormSection>
      <FormSection title="Timeline Tag">
        <HFDatePicker
          minDate={new Date(1700, 0, 1)}
          name="startDate"
        />
        <HFDatePicker
          minDate={new Date(1700, 0, 1)}
          name="endDate"
        />
      </FormSection>
      <FormSection title="Other Matches">
        <List
          formProps={formProps}
          InputRenderer={TextField}
          label="Other Matches"
          name="otherMatches"
        />
      </FormSection>
      <FormSection title="Sample Sentence">
        <HFTextField
          name="sampleSentence"
          noLabel
        />
      </FormSection>
      <HFTextField name="abbreviation" />
      <HFTextField name="synonyms" />
      <HFTextField name="antonyms" />

      <MediaList uploadType="image" />
      <MediaList uploadType="video" />
      <MediaList uploadType="audio" />
      <FormSection title="Test Prep Questions">
        TODO: can we reuse multiple choice builder here?
      </FormSection>
      <Headline
        size={3}
        title="Spanish">
        <HFSwitch name="cognate" />
        <HFTextField
          label="Spanish term"
          name="espTerm"
        />
        <HFTextField
          label="Spanish definition"
          name="espDefinition"
        />
      </Headline>
    </Form>
  )
}

Vocabulary.propTypes = formPropsShape.isRequired

const VocabularyWithContent = withContent(Vocabulary, {
  contentType: CONTENT_TYPE_VOCABULARY,
})

export default VocabularyWithContent

import DeleteIcon from '@mui/icons-material/DeleteForever'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import { pick } from 'fp/objects'
// import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const ListItemRenderer = forwardRef(
  ({ allowDeletion, disabled, index, onChange, removeItem, ...rest }, ref) => (
    <DebouncedTextField
      {...{ disabled, ref, ...pick(['className', 'name', 'value'])(rest) }}
      InputProps={
        allowDeletion
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={disabled}
                      onClick={() => removeItem(index)}
                      size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }
          : undefined
      }
      margin="dense"
      onChange={({ target }) => onChange(target.value)}
    />
  ),
)

ListItemRenderer.propTypes = itemRendererProps

const Styled = styled(ListItemRenderer, { name: 'Callouts-ListItemRenderer' })({
  '.MuiIconButton-root': {
    paddingRight: 0,
  },
})

export default Styled

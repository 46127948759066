import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
} from 'core/consts'
import withContent from 'hoc/withContent'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import GroupAndSortInteractive from 'hss/sections/contentBlocks/interactives/GroupAndSort'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'
import defaultColumn from './defaultColumn'

const contentSubType = INTERACTIVE_TYPE_GROUP_AND_SORT
const Interactive = GroupAndSortInteractive
const defaultValues = {
  columns: [defaultColumn(), defaultColumn()],
  prompt: '',
  showGroupTotals: false,
  variant: interactiveVariants.NORMAL,
  scoring: {
    mode: 'none',
  },
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const GroupAndSort = formProps => (
  <Form
    name="Interactive-GroupAndSort"
    standardsAllowed
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    variants={{
      [interactiveVariants.NORMAL]: {
        displayName: 'Normal',
      },
      [interactiveVariants.GROUP_AND_SORT_VOCAB_FAMILIARITY]: {
        displayName: 'Familiar & Unfamiliar Vocabulary Words',
        defaults: {
          prompt:
            'Sort the vocabulary words as familiar or unfamiliar based on your knowledge of them.',
        },
      },
    }}>
    <Content />
  </Form>
)

const GroupAndSortWithContent = withContent(GroupAndSort, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default GroupAndSortWithContent

import Well from 'common/layout/Well'
import { SCORING_MODES } from 'hss/ContentBuilder/consts'
import { useWatch } from 'react-hook-form'
import AllOrNothing from './AllOrNothing'
import MaxScore from './MaxScore'
import ScoringMode from './ScoringMode'

const Scoring = () => {
  const contentSubType = useWatch({ name: 'contentSubType' })

  return SCORING_MODES[contentSubType] ? (
    <Well>
      <ScoringMode />

      <MaxScore />

      <AllOrNothing />
    </Well>
  ) : null
}

export default Scoring

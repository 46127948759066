import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
} from 'core/consts'
import withContent from 'hoc/withContent'
import PlotDiagramInteractive from 'hss/sections/contentBlocks/interactives/PlotDiagram'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import { QuestionPromptWithHeadline } from '../Form/QuestionPrompt'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_PLOT_DIAGRAM
const Interactive = PlotDiagramInteractive

const defaultValues = {
  climax: '',
  exposition: '',
  fallingAction: [],
  resolution: '',
  risingAction: [],
  scorable: false,
  readOnly: false,
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const PlotDiagram = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    fullWidth
    name="Interactive-Plot-Diagram"
    studentInstructionsAllowed>
    <QuestionPromptWithHeadline name="prompt" />
    <Content />
  </Form>
)

const PlotDiagramWithContent = withContent(PlotDiagram, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default PlotDiagramWithContent

import CharacterLimit from './CharacterLimit'
import InteractiveInstructions from './InteractiveInstructions'
import QuestionPrompt from './QuestionPrompt'
import StudentInstructions from './StudentInstructions'
import { featurePaneShape } from './utils'

const FeaturePictureThis = ({ disabled, feature }) => (
  <>
    <InteractiveInstructions
      disabled={disabled}
      feature={feature}
    />

    <QuestionPrompt
      disabled={disabled}
      feature={feature}
    />

    <StudentInstructions
      disabled={disabled}
      feature={feature}
    />

    <CharacterLimit
      disabled={disabled}
      feature={feature}
    />
  </>
)

FeaturePictureThis.propTypes = featurePaneShape

export default FeaturePictureThis

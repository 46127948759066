import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from 'common/dialogs/Dialog'
import { TERRITORY_SELECTION_TYPE_EVERYONE } from 'core/consts'
import { isEmptyString } from 'fp/strings'
import { when } from 'fp/utils'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import SelectionType from './SelectionType'
import Selections from './Selections'

const TerritoriesDialog = props => {
  const { open, toggleOpen } = props
  const [territorySelections, territorySelectionType] = useWatch({
    name: ['territorySelections', 'territorySelectionType'],
  })
  const { setValue } = useFormContext()

  const [type, setType] = useStateWithDynamicDefault(territorySelectionType)
  const [selections, setSelections] =
    useStateWithDynamicDefault(territorySelections)

  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    setCanSubmit(
      type === TERRITORY_SELECTION_TYPE_EVERYONE || selections.length > 0,
    )
  }, [type, selections])

  const handleApply = () => {
    setValue('territorySelectionType', type)
    setValue('territorySelections', selections)

    toggleOpen()
  }

  const handleClose = (_, reason) => {
    when(isEmptyString(reason), toggleOpen)
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      showCloseButton
      title="Territorial Visibility">
      <DialogContent>
        <SelectionType
          onChange={setType}
          value={type}
        />

        {type !== TERRITORY_SELECTION_TYPE_EVERYONE && (
          <Selections
            onChange={setSelections}
            value={selections}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={toggleOpen}
          variant="secondary">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={!canSubmit}
          onClick={handleApply}
          variant="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TerritoriesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
}

export default TerritoriesDialog

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Headline from 'common/text/Headline'
import { CONTENT_TYPE_ECHO, TEACHER_EDITION_PREFIX } from 'core/consts'
import { omit, set } from 'fp/objects'
import { identity } from 'fp/utils'
import withProps from 'hoc/withProps'
import { builderContext } from 'hss/ContentBuilder/interactives/Form/builderContext'
import { Block } from 'hss/ContentViewer/Chapter/Block'
import StyledContent from 'hss/ContentViewer/StyledContent'
import PropTypes from 'prop-types'
import { Fragment, useContext } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getParentFromContentId } from 'selectors/contentViewer'
import AccentColorProvider from 'styling/theming/AccentColorProvider'
import { important } from 'styling/theming/base/mixins'

const Preview = ({ currentContentType, split = false }) => {
  const all = useWatch()

  const { currentBodyType } = useContext(builderContext)

  const isTe = currentContentType === 'te'

  const data = {
    body: isTe ? all[`${TEACHER_EDITION_PREFIX}body`] : all[currentBodyType],
    fullWidth: all.fullWidth,
    variant: isTe ? '' : all.variant,
  }

  const content = compose(
    isTe ? set('uploadsMap', {}) : identity,
    set('data', data),
    omit('body', `${TEACHER_EDITION_PREFIX}body`, 'fullWidth'),
  )(all)

  const { contentType: parentContentType } =
    useSelector(
      getParentFromContentId({
        contentId: content.id,
        contentType: content.contentType,
      }),
    ) || {}

  const Wrapper =
    parentContentType === CONTENT_TYPE_ECHO
      ? withProps(AccentColorProvider, { accentColor: 'canary' })
      : Fragment

  return (
    <Box>
      {Boolean(split) && (
        <Headline
          hr
          pt={2}
          size={4}
          sx={{
            pl: split ? 2 : 0,
            bgcolor: 'grey.5',
            transform: 'translateY(-1px)',
            marginBottom: important(0),
          }}
          title="Preview"
        />
      )}

      <Typography
        display="block"
        mb={1}
        pr={2}
        textAlign="right"
        variant="footnote">
        * - content wrapping is not depicted within preview
      </Typography>

      <Wrapper>
        <StyledContent>
          <table
            className={`block-layout preview-layout ${parentContentType}-blocks`}
            role="presentation">
            <tbody>
              <Block
                blockBundle={{ block: content, seRowSpan: 1, teRowSpan: 0 }}
                disableFullWidth={isTe}
                isLast
                previewing
              />
            </tbody>
          </table>
        </StyledContent>
      </Wrapper>
    </Box>
  )
}

Preview.propTypes = {
  currentContentType: PropTypes.oneOf(['se', 'te']).isRequired,
  split: PropTypes.bool,
}

export default Preview

import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import HFSelect from 'common/formControls/selects/HFSelect'
import Select from 'common/formControls/selects/Select'
import {
  CONTENT_SUBTYPE_UNIT_NORMAL,
  CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL,
} from 'core/consts'
import { isDefined } from 'fp/utils'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

export const unitContentSubTypes = [
  {
    value: CONTENT_SUBTYPE_UNIT_NORMAL,
    title: 'Normal',
    label: 'Is included within the course unit count.',
  },
  {
    value: CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL,
    title: 'Supplemental',
    label: 'Is NOT included within the course unit count.',
  },
]

const UnitContentSubTypePicker = props => {
  const { onChange, value } = props
  const isControlled = isDefined(onChange)

  const handleChange = useCallback(
    ({ target }) => {
      onChange({ value: target.value })
    },
    [onChange],
  )

  const Component = isControlled
    ? withProps(Select, {
        onChange: handleChange,
        value,
        sx: {
          width: '100%',
        },
      })
    : HFSelect

  return (
    <Component
      label="Content Sub Type"
      name="contentSubType"
      renderValue={selected => {
        const item = unitContentSubTypes.find(
          ({ value: itemValue }) => itemValue === selected,
        )
        return item ? item.title : ''
      }}>
      {unitContentSubTypes.map(({ value: subTypeValue, label, title }) => (
        <MenuItem
          divider
          key={subTypeValue}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          value={subTypeValue}>
          <Typography variant="nav-item-semibold">{title}</Typography>
          <Typography>{label}</Typography>
        </MenuItem>
      ))}
    </Component>
  )
}

UnitContentSubTypePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default UnitContentSubTypePicker

import {
  ASSESSMENT_TYPE_BENCHMARK,
  ASSESSMENT_TYPE_DIAGNOSTIC,
  ASSESSMENT_TYPE_END_OF_UNIT,
  ASSESSMENT_TYPE_READINESS_SCREENER,
  ASSESSMENT_TYPE_TEST_PREP,
  CONTENT_STATE_DRAFT,
  CONTENT_STATE_PUBLISHED,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_SUBTYPE_SKILLS_ROLLUP,
  CONTENT_SUBTYPE_STANDARDS_ROLLUP,
  CONTENT_SUBTYPE_VOCAB_ROLLUP,
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DGO_TEMPORARY,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_LINK,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
  QUESTION_TYPE_ANNOTATE,
  QUESTION_TYPE_AUDIO,
  QUESTION_TYPE_CHART,
  QUESTION_TYPE_CHOICE_MATRIX,
  QUESTION_TYPE_CLOZE,
  QUESTION_TYPE_EBSR,
  QUESTION_TYPE_ESSAY,
  QUESTION_TYPE_GROUP_AND_SORT,
  QUESTION_TYPE_INSTRUCTIONS,
  QUESTION_TYPE_MULTIPLE_CHOICE,
} from 'core/consts'
import { interactiveTypeShape } from 'core/shapes'
import PropTypes from 'prop-types'
import {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TALK_BACK,
  ECHO_FEATURE_TOP_TEN,
  SCORING_MODES,
  SCORING_MODE_CHART_COLUMN,
  SCORING_MODE_CHART_EXACT,
  SCORING_MODE_CHART_ROW,
  SCORING_MODE_CHART_ROW_COMPLETE,
  SCORING_MODE_EVAL_RANKING_EXACT,
  SCORING_MODE_GNS_GROUP,
  SCORING_MODE_GNS_GROUP_AND_SORT,
  SCORING_MODE_GNS_SORT_GIVEN_GROUPS,
  SCORING_MODE_IMAGE_TITLE_DRAG,
  SCORING_MODE_MULTICHOICE_ALL,
  SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED,
  SCORING_MODE_MULTICHOICE_WEIGHTED,
  SCORING_MODE_NONE,
  SCORING_MODE_RUBRIC,
} from 'shared/consts'
import { absHeight, px, rem } from 'styling/theming/base/mixins'

export {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TALK_BACK,
  ECHO_FEATURE_TOP_TEN,
  SCORING_MODE_CHART_COLUMN,
  SCORING_MODE_CHART_EXACT,
  SCORING_MODE_CHART_ROW,
  SCORING_MODE_CHART_ROW_COMPLETE,
  SCORING_MODE_EVAL_RANKING_EXACT,
  SCORING_MODE_GNS_GROUP,
  SCORING_MODE_GNS_GROUP_AND_SORT,
  SCORING_MODE_GNS_SORT_GIVEN_GROUPS,
  SCORING_MODE_IMAGE_TITLE_DRAG,
  SCORING_MODE_MULTICHOICE_ALL,
  SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED,
  SCORING_MODE_MULTICHOICE_WEIGHTED,
  SCORING_MODE_NONE,
  SCORING_MODE_RUBRIC,
  SCORING_MODES,
}

export const labels = {
  [ASSESSMENT_TYPE_DIAGNOSTIC]: 'Diagnostic',
  [ASSESSMENT_TYPE_READINESS_SCREENER]: 'Readiness Screener',
  [ASSESSMENT_TYPE_BENCHMARK]: 'Benchmark',
  [ASSESSMENT_TYPE_TEST_PREP]: 'Test Prep',
  [ASSESSMENT_TYPE_END_OF_UNIT]: 'End-of-Unit',

  [CONTENT_SUBTYPE_CHAPTER_SUMMARY]: 'Chapter Summary',

  [CONTENT_TYPE_ASSESSMENT]: 'Assessment',
  [CONTENT_TYPE_BLOCK]: 'Block',
  [CONTENT_TYPE_CHAPTER]: 'Chapter',
  [CONTENT_TYPE_COURSE]: 'Course',
  [CONTENT_TYPE_ECHO]: 'Echo',
  [CONTENT_TYPE_INTERACTIVE]: 'Interactive',
  [CONTENT_TYPE_PAGE]: 'Page',
  [CONTENT_TYPE_PAGESET]: 'Page Set',
  [CONTENT_TYPE_RUBRIC]: 'Rubric',
  [CONTENT_TYPE_SCAFFOLD]: 'Scaffold',
  [CONTENT_TYPE_SECTION]: 'Section',
  [CONTENT_TYPE_SOURCE]: 'Source',
  [CONTENT_TYPE_SUBSECTION]: 'Subsection',
  [CONTENT_TYPE_UNIT]: 'Unit',
  [CONTENT_TYPE_VOCABULARY]: 'Vocab',

  [ECHO_FEATURE_ACT]: 'Act',
  [ECHO_FEATURE_BLASTY_BLAST]: 'Respond',
  [ECHO_FEATURE_PICTURE_THIS]: 'Respond',
  [ECHO_FEATURE_POLL]: 'Poll',
  [ECHO_FEATURE_RATE]: 'Rate',
  [ECHO_FEATURE_TALK_BACK]: 'Respond',
  [ECHO_FEATURE_TOP_TEN]: 'Top 10',

  [INTERACTIVE_TYPE_ACTIVITY]: 'Activity',
  [INTERACTIVE_TYPE_ANIMATOR]: 'Animator',
  [INTERACTIVE_TYPE_AUDIO_RECORDING]: 'Audio Recording',
  [INTERACTIVE_TYPE_AUDIO]: 'Audio',
  [INTERACTIVE_TYPE_CHART_COMPLETION]: 'Chart Completion',
  [INTERACTIVE_TYPE_CHART]: 'Chart',
  [INTERACTIVE_TYPE_DGO_TABLE]: 'Table',
  [INTERACTIVE_TYPE_DGO_TEMPORARY]: 'Scaffold',
  [INTERACTIVE_TYPE_DRAW_TOOL]: 'Draw Tool',
  [INTERACTIVE_TYPE_ESSAY]: 'Essay',
  [INTERACTIVE_TYPE_EVALUATION_RANKING]: 'Evaluation/Ranking',
  [INTERACTIVE_TYPE_FLIPBOOK]: 'Flipbook',
  [INTERACTIVE_TYPE_FUN_FACTS]: 'Fun Fact',
  [INTERACTIVE_TYPE_GROUP_AND_SORT]: 'Group & Sort',
  [INTERACTIVE_TYPE_HOTSPOT]: 'Hotspot',
  [INTERACTIVE_TYPE_IMAGE_CLICKER]: 'Image Clicker',
  [INTERACTIVE_TYPE_IMAGE_TITLE_DRAG]: 'Image Title Drag',
  [INTERACTIVE_TYPE_IMAGE_ZOOMY]: 'Image Zoomer',
  [INTERACTIVE_TYPE_IMAGE]: 'Image',
  [INTERACTIVE_TYPE_LINEAR_SCALE]: 'Linear Scale',
  [INTERACTIVE_TYPE_LINK]: 'Link',
  [INTERACTIVE_TYPE_MULTIPLE_CHOICE]: 'Multiple Choice',
  [INTERACTIVE_TYPE_PACING_GUIDE]: 'Pacing Guide',
  [INTERACTIVE_TYPE_PLOT_DIAGRAM]: 'Plot Diagram',
  [INTERACTIVE_TYPE_POLL]: 'Poll',
  [INTERACTIVE_TYPE_STACKED_SEQUENCE]: 'Stacked Sequence',
  [INTERACTIVE_TYPE_TIMELINE]: 'Timeline',
  [INTERACTIVE_TYPE_VENN_DIAGRAM]: 'Venn Diagram',
  [INTERACTIVE_TYPE_VIDEO]: 'Video',
  [INTERACTIVE_TYPE_WORD_CLOUD]: 'Word Cloud',

  [QUESTION_TYPE_INSTRUCTIONS]: 'Instructions',
  [QUESTION_TYPE_ANNOTATE]: 'Annotate',
  [QUESTION_TYPE_AUDIO]: 'Audio',
  [QUESTION_TYPE_CHART]: 'Chart',
  [QUESTION_TYPE_CHOICE_MATRIX]: 'Choice Matrix',
  [QUESTION_TYPE_CLOZE]: 'Cloze',
  [QUESTION_TYPE_EBSR]: 'EBSR',
  [QUESTION_TYPE_ESSAY]: 'Essay',
  [QUESTION_TYPE_GROUP_AND_SORT]: 'Group & Sort',
  [QUESTION_TYPE_MULTIPLE_CHOICE]: 'Multiple Choice',
}

export const builderContentSubTypesShape = PropTypes.oneOfType([
  interactiveTypeShape,
  PropTypes.oneOf([CONTENT_SUBTYPE_CHAPTER_SUMMARY]),
])

export const contentStateLabels = {
  [CONTENT_STATE_DRAFT]: 'Draft',
  [CONTENT_STATE_PUBLISHED]: 'Published',
}

export const displayConfig = {
  defaults: {
    contentSx: {
      bgcolor: 'grey.5',
      marginBottom: rem(3),
      padding: rem(0, 4, 4),
      paddingTop: px(1),
    },
    expandable: true,
    expandedSx: {
      ...absHeight('calc(100vh - 10rem)'),
      overflow: 'auto',
      paddingBottom: rem(2),
    },
    headerSx: {
      bgcolor: 'grey.5',
    },
    framelessHeaderSx: {
      paddingBottom: rem(3),
    },
    preamble: true,
    preambleSx: {
      marginBottom: rem(3),
      marginTop: rem(6),
      position: 'relative',
    },
  },

  [INTERACTIVE_TYPE_ANIMATOR]: {
    contentSx: {
      padding: 0,
    },
    expandable: false,
    preambleSx: {
      marginBottom: 0,
    },
  },

  [INTERACTIVE_TYPE_AUDIO]: {
    contentSx: {
      bgcolor: 'unset',
      padding: rem(0, 0, 2.4),
      '.MuiTypography-root > p': {
        marginBottom: 0,
      },
    },
    framelessHeaderSx: {
      minHeight: 0,
    },
    preambleSx: {
      marginBottom: 0,
    },
    expandable: false,
    preamble: true,
  },

  [INTERACTIVE_TYPE_AUDIO_RECORDING]: {
    contentSx: {
      bgcolor: 'unset',
      padding: rem(8, 0, 2.4),
    },
    expandable: false,
    preamble: false,
  },

  [INTERACTIVE_TYPE_CHART]: {
    contentSx: {
      cursor: 'pointer',
      svg: { cursor: 'pointer' },
      marginBottom: 0,
      figure: { padding: rem(3.2) },
    },
    framelessContentSx: {
      bgcolor: 'background.paper',
      padding: 0,
    },
    framelessHeaderSx: {
      display: 'none',
    },
    preambleSx: {
      'h3, h4': {
        display: 'none',
      },
    },
    expandable: true,
  },

  [INTERACTIVE_TYPE_DRAW_TOOL]: {
    expandedSx: {
      '.tl-container': {
        height: '95%',
      },
    },
  },

  [INTERACTIVE_TYPE_FLIPBOOK]: {
    contentSx: {
      marginTop: 2,
      padding: rem(2, 4),
    },
    expandedHeaderSx: {
      bgcolor: 'background.default',
      position: 'static',
    },
    expandedSx: {
      '.MuiBox-root .flipbook-slide': {
        height: '75vh',
        width: '40vh',
      },
      '.MuiStack-root > div': {
        height: '80vh',
      },
    },
    headerSx: {
      bgcolor: 'transparent',
      marginBottom: 0,
      top: -25,
      right: 0,
      zIndex: 'appBar',
    },
    preambleSx: {
      marginBottom: 0,
    },
  },

  [INTERACTIVE_TYPE_HOTSPOT]: {
    contentSx: {
      padding: rem(2, 4, 4),
    },
  },

  [INTERACTIVE_TYPE_IMAGE]: {
    contentSx: {
      bgcolor: 'unset',
      padding: 0,
      margin: rem(3.2, 0),
    },
    expandable: false,
    preamble: false,
  },

  [INTERACTIVE_TYPE_LINK]: {
    inline: true,
  },

  [INTERACTIVE_TYPE_PACING_GUIDE]: {
    expandedSx: {
      paddingBottom: rem(10),
    },
  },

  [INTERACTIVE_TYPE_TIMELINE]: {
    contentSx: {
      bgcolor: 'background.paper',
      padding: 0,
      zIndex: 'unset!important', // for embedded full screen images
    },
    expandedSx: { 'MuiBox-root': absHeight('calc(100vh - 6rem)') },
    expandable: true,
    preamble: true,
  },

  [INTERACTIVE_TYPE_VIDEO]: {
    contentSx: {
      bgcolor: 'inherit',
      marginBottom: 0,
      padding: 0,
    },
    expandable: false,
    instructions: '',
  },
}

export const framelessByDefault = [
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VIDEO,
]

export const interactiveVariants = {
  NORMAL: 'normal',
  CHART_COMPLETION_VOCAB_DEFINITIONS: 'chartCompletionVocabDefinitions',
  CHART_COMPLETION_VOCAB_SENTENCES: 'chartCompletionVocabSentences',
  EVALUATION_RANKING_VOCAB_FAMILIARITY: 'evaluationRankingVocabFamiliarity',
  GROUP_AND_SORT_VOCAB_FAMILIARITY: 'groupAndSortVocabFamiliarity',
  TIMELINE_ECON: 'timelineEcon',
  WORD_CLOUD_CHOOSE_VOCAB_WORDS: 'wordCloudChooseVocabWords',
}

export const newlyAddedChildId = '_not_yet_committed_'

export const rollupContentSubTypes = [
  CONTENT_SUBTYPE_SKILLS_ROLLUP,
  CONTENT_SUBTYPE_STANDARDS_ROLLUP,
  CONTENT_SUBTYPE_VOCAB_ROLLUP,
]

export const rollupContentSubTypeLabels = {
  [CONTENT_SUBTYPE_SKILLS_ROLLUP]: 'Skills',
  [CONTENT_SUBTYPE_STANDARDS_ROLLUP]: 'Standards',
  [CONTENT_SUBTYPE_VOCAB_ROLLUP]: 'Vocab',
}

export const scoringModesWhereGroupOrderMatters = [
  SCORING_MODE_GNS_GROUP_AND_SORT,
  SCORING_MODE_GNS_SORT_GIVEN_GROUPS,
]

export const SUBMITTABLE_ECHO_FEATURES = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
]

import Grid from '@mui/material/Grid'
import Backdrop from 'common/Draggable/Backdrop'
import { listShape } from 'common/formControls/lists/BasicList'
import withList from 'common/formControls/lists/withList'
import Headline from 'common/text/Headline'
import { useWatch } from 'react-hook-form'

const Callouts = ({ ListRenderer, ItemRenderer, ...rest }) => {
  const [backdrop, darkBackdropImage] = useWatch({
    name: ['uploadsMap.backdrop', 'darkBackdropImage'],
  })

  return backdrop ? (
    <Headline
      hr
      mt={4}
      title="Pins">
      <Grid container>
        <Grid
          item
          lg={12}
          xl={3}>
          <ListRenderer {...rest} />
        </Grid>

        <Grid
          item
          lg={12}
          xl={9}>
          <Backdrop
            darkBackdropImage={darkBackdropImage}
            ItemRenderer={ItemRenderer}
            upload={backdrop}
            {...rest}
          />
        </Grid>
      </Grid>
    </Headline>
  ) : null
}

Callouts.propTypes = listShape

const WiredCallouts = withList(Callouts)

export default WiredCallouts

import { generateId } from 'fp/utils'

const defaultColumn = () => ({
  id: generateId(),
  heading: '',
  rows: [
    { cellText: '', id: generateId() },
    { cellText: '', id: generateId() },
  ],
})

export default defaultColumn

import RemoveCircle from '@mui/icons-material/RemoveCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HFCheckbox from 'common/formControls/switches/HFCheckbox'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import Centered from 'common/layout/Centered'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import QuestionPrompt from '../Form/QuestionPrompt'
import Rows from './Rows'
import defaultColumn from './defaultColumn'

const { GROUP_AND_SORT_VOCAB_FAMILIARITY } = interactiveVariants

const Content = () => {
  const { append, fields, remove } = useFieldArray({ name: 'columns' })
  const { sidebarWidth } = useContext(sidebarContext)
  const [userHasModifiedCols, setUserHasModifiedCols] = useState()

  useEffect(() => {
    if (userHasModifiedCols) {
      /*
        There were issues trying to pass in a ref to withHookForm,
        so for now we're just going to focus the old-fashioned way.
      */
      setTimeout(() => {
        document
          .getElementsByName(`columns.${fields.length - 1}.heading`)[0]
          .focus()
      })
    }
  }, [fields.length, userHasModifiedCols])

  const addColumn = () => {
    setUserHasModifiedCols(true)
    append(defaultColumn())
  }

  const removeColumn = idx => {
    setUserHasModifiedCols(true)
    remove(idx)
  }

  const variant = useWatch({ name: 'variant' })

  return (
    <>
      <QuestionPrompt name="prompt" />

      {variant !== GROUP_AND_SORT_VOCAB_FAMILIARITY && (
        <>
          <Grid
            container
            spacing={2}
            sx={{ mt: 3, width: `calc(100vw - ${sidebarWidth + 100}px)` }}>
            {fields.map((field, idx) => (
              <Grid
                item
                key={field.id}
                lg={4}
                md={6}
                minWidth={250}
                xl={3}
                xs={12}>
                <Box
                  border={1}
                  component="fieldset"
                  display="flex"
                  flexDirection="column"
                  padding={2}
                  sx={{ borderColor: 'grey.3' }}>
                  <legend>Column {idx + 1}</legend>
                  <HFTextField
                    label="Heading"
                    name={`columns.${idx}.heading`}
                    required
                  />

                  <Box flexGrow="1">
                    <Rows colIdx={idx} />
                  </Box>

                  {fields.length > 2 && (
                    <Centered>
                      <Tooltip title={`Remove Column ${idx + 1}`}>
                        <IconButton
                          data-testid={`remove-col-btn-${idx}`}
                          onClick={() => removeColumn(idx)}>
                          <RemoveCircle />
                        </IconButton>
                      </Tooltip>
                    </Centered>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>

          {fields.length < 6 && (
            <Box mt={2}>
              <Button
                onClick={addColumn}
                size="small">
                Add Column
              </Button>
            </Box>
          )}

          <HFCheckbox name="showGroupTotals" />
        </>
      )}
    </>
  )
}

export default Content

import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import CollapsibleSection, {
  StylerForSingleField,
} from 'common/layout/CollapsibleSection'
import { isEmptyString } from 'fp/strings'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

const StudentInstructions = ({ className, name = 'studentInstructions' }) => {
  const studentInstructions = useWatch({ name })

  return (
    <CollapsibleSection
      className={className}
      empty={isEmptyString(studentInstructions)}
      label="Student Instructions">
      <HFRichTextEdit
        aria-label="student instructions"
        label=""
        name={name}
      />
      <HFAssetUploader
        label="Student Instructions Audio File"
        name="uploadsMap.studentInstructionsAudio"
        uploadType="audio"
      />
    </CollapsibleSection>
  )
}

StudentInstructions.propTypes = {
  name: PropTypes.string,
}

const StyledStudentInstructions = StylerForSingleField(StudentInstructions)

export default StyledStudentInstructions

import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import ChildListSection from '../ChildListSection'
import Info from './Info'

const ChapterSummary = ({ formProps }) => (
  <>
    <ChildListSection
      binaryMode
      contentSubTypes={[CONTENT_SUBTYPE_CHAPTER_SUMMARY]}
      contentTypes={[CONTENT_TYPE_SUBSECTION]}
      formProps={formProps}
      name="children"
      title="Chapter Summary"
    />

    <Info />

    <hr />
  </>
)

ChapterSummary.propTypes = {
  formProps: formPropsShape.isRequired,
}

export default ChapterSummary

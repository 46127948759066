import DraggableItem from 'common/Draggable/DraggableItem'
import { TextRenderer } from 'common/Draggable/DraggableRenderer'
import { imageClickerMessageShape } from 'core/shapes'

const MessageRenderer = ({ item: { coord, id, message } }) => (
  <DraggableItem
    coordinates={coord}
    itemId={id}
    Renderer={TextRenderer}
    type="MESSAGE">
    {message}
  </DraggableItem>
)

MessageRenderer.propTypes = {
  item: imageClickerMessageShape.isRequired,
}

export default MessageRenderer

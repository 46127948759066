import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFTextArea from 'common/formControls/textInputs/HFTextArea'
import Callouts from '../ImageTitleDrag/Callouts'
import MessageRenderer from './MessageRenderer'
import Messages from './Messages'

const Content = () => (
  <>
    <ContainedFormContent>
      <HFTextArea
        label="Image Description"
        name="imageAltText"
        required
      />

      <HFAssetUploader
        label="Backdrop Image"
        name="uploadsMap.backdrop"
        required
        uploadType="image"
      />
    </ContainedFormContent>

    <Callouts
      dropTargets={['MESSAGE']}
      ItemRenderer={MessageRenderer}
      ListRenderer={Messages}
      name="messages"
    />
  </>
)

export default Content

import HFSwitch from 'common/formControls/switches/HFSwitch'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import DGOTableInteractive from 'hss/sections/contentBlocks/interactives/DGOTable'
import { useMemo } from 'react'
import { INTERACTIVE_TYPE_DGO_TABLE } from 'shared/consts'
import Table from '../ChartCompletion/Table/index.js'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import { QuestionPromptWithHeadline } from '../Form/QuestionPrompt'
import Cell from './Cell'

const contentSubType = INTERACTIVE_TYPE_DGO_TABLE
const Interactive = DGOTableInteractive
const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const DGOTable = formProps => {
  const defaultValues = useMemo(() => {
    const rowId = generateId()
    const [column1Id, column2Id] = [generateId(), generateId()]
    return {
      allowRowEdits: false,
      columns: [
        { id: column1Id, header: '' },
        { id: column2Id, header: '' },
      ],
      rows: [{ id: rowId }],
      items: [
        {
          value: '',
          id: generateId(),
          column: column1Id,
          row: rowId,
          displayText: false,
        },
        {
          value: '',
          id: generateId(),
          column: column2Id,
          row: rowId,
          displayText: false,
        },
      ],
    }
  }, [])
  return (
    <Form
      {...{ contentSubType, defaultValues, formProps, previewButton }}
      fullWidth
      name="Interactive-DGO-table"
      standardsAllowed>
      <QuestionPromptWithHeadline name="prompt" />
      <Table
        allowColumnEdits
        allowRowEdits
        CellRenderer={Cell}
        newItemProps={{ displayText: false, value: '' }}
      />
      <HFSwitch
        label="Allow students to add or remove rows?"
        name="allowStudentRowEdits"
      />
    </Form>
  )
}

const DGOTableWithContent = withContent(DGOTable, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default DGOTableWithContent

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AdvancedList from 'common/formControls/lists/AdvancedList'
import { filter } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo, identity, matches, takeSecond } from 'fp/utils'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { compose } from 'redux'
import QuestionPrompt from '../Form/QuestionPrompt'
import AllowMultipleSelectionSwitch from './AllowMultipleSelectionSwitch'
import AnswerChoiceItemRenderer from './AnswerChoiceItemRenderer'
import CorrectAnswerWarning from './CorrectAnswerWarning'
import LimitSelectionsSwitch from './LimitSelectionsSwitch'

const rules = {
  validate: {
    hasCorrectAnswer: compose(
      // Returning a string sets the rule's error message. Returning true causes the rule to pass validation.
      fallbackTo('Select at least one correct answer.', true),
      Boolean,
      get('length'),
      filter(matches('isCorrect', true)),
      get('value'),
    ),
  },
}

const Content = ({ newAnswerChoice }) => {
  const answerChoices = useWatch({ name: 'answerChoices' })
  const numberCorrect = answerChoices.filter(get('isCorrect')).length

  return (
    <>
      <QuestionPrompt />

      <Box mt={4}>
        <AdvancedList
          getter={identity}
          itemContainerProps={{
            paddingBottom: 2,
          }}
          ItemRenderer={AnswerChoiceItemRenderer}
          minimumItems={2}
          name="answerChoices"
          noLabel
          rules={rules}
          setter={takeSecond}>
          {({ addItem, disabled }) => (
            <Button
              disabled={disabled}
              onClick={compose(addItem, newAnswerChoice)}>
              Add answer choice
            </Button>
          )}
        </AdvancedList>

        <AllowMultipleSelectionSwitch numberCorrect={numberCorrect} />
        <LimitSelectionsSwitch numberCorrect={numberCorrect} />

        <Box mt={4}>
          <CorrectAnswerWarning />
        </Box>
      </Box>
    </>
  )
}

Content.propTypes = {
  newAnswerChoice: PropTypes.func.isRequired,
}

export default Content

import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import {
  ABILITY_CONTENT_CREATION,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
} from 'core/consts'
import { historyShape } from 'core/shapes'
import { mapValues, set } from 'fp/objects'
import { createElement } from 'react'
/* istanbul ignore file */
import { Routes } from 'react-router-dom'
import Route404 from 'routing/Route404'
import { configToRoutes } from 'routing/TraverseRoute'
import Block from './Curriculum/Block'
import Chapter from './Curriculum/Chapter'
import Course from './Curriculum/Course'
import Echo from './Curriculum/Echo'
import Page from './Curriculum/Page'
import Pageset from './Curriculum/Pageset'
import Scaffold from './Curriculum/Scaffold'
import Section from './Curriculum/Section'
import Source from './Curriculum/Source'
import Subsection from './Curriculum/Subsection'
import Unit from './Curriculum/Unit'
import Vocabulary from './Curriculum/Vocabulary'
import Activity from './interactives/Activity'
import Animator from './interactives/Animator'
import Audio from './interactives/Audio'
import AudioRecording from './interactives/AudioRecording'
import Chart from './interactives/Chart'
import ChartCompletion from './interactives/ChartCompletion'
import DGOTable from './interactives/DGOTable'
import DrawTool from './interactives/DrawTool'
import Essay from './interactives/Essay'
import EvaluationRanking from './interactives/EvaluationRanking'
import Flipbook from './interactives/Flipbook'
import FunFacts from './interactives/FunFacts'
import GroupAndSort from './interactives/GroupAndSort'
import Hotspot from './interactives/Hotspot'
import Image from './interactives/Image'
import ImageClicker from './interactives/ImageClicker'
import ImageTitleDrag from './interactives/ImageTitleDrag'
import ImageZoomy from './interactives/ImageZoomy'
import LinearScale from './interactives/LinearScale'
import MultipleChoice from './interactives/MultipleChoice'
import PacingGuide from './interactives/PacingGuide'
import PlotDiagram from './interactives/PlotDiagram'
import Poll from './interactives/Poll'
import Rubric from './interactives/Rubric'
import StackedSequence from './interactives/StackedSequence'
import Timeline from './interactives/Timeline'
import VennDiagram from './interactives/VennDiagram'
import Video from './interactives/Video'
import WordCloud from './interactives/WordCloud'
import { withEdit } from './utils'

const config = mapValues(set('abilityFlags', ABILITY_CONTENT_CREATION))({
  /** ***************************** CONTENT ********************************* */

  block: {
    path: `/curriculum/${CONTENT_TYPE_BLOCK}/:contentId`,
    element: createElement(withEdit(Block)),
  },

  chapter: {
    path: `/curriculum/${CONTENT_TYPE_CHAPTER}/:contentId`,
    element: createElement(withEdit(Chapter)),
  },

  course: {
    path: `/curriculum/${CONTENT_TYPE_COURSE}/:contentId`,
    element: createElement(withEdit(Course)),
  },

  echo: {
    path: `/curriculum/${CONTENT_TYPE_ECHO}/:contentId`,
    element: createElement(withEdit(Echo)),
  },

  page: {
    path: `/curriculum/${CONTENT_TYPE_PAGE}/:contentId`,
    element: createElement(withEdit(Page)),
  },

  pageset: {
    path: `/curriculum/${CONTENT_TYPE_PAGESET}/:contentId`,
    element: createElement(withEdit(Pageset)),
  },

  scaffold: {
    path: `/curriculum/${CONTENT_TYPE_SCAFFOLD}/:contentId`,
    element: createElement(withEdit(Scaffold)),
  },

  section: {
    path: `/curriculum/${CONTENT_TYPE_SECTION}/:contentId`,
    element: createElement(withEdit(Section)),
  },

  source: {
    path: `/curriculum/${CONTENT_TYPE_SOURCE}/:contentId`,
    element: createElement(withEdit(Source)),
  },

  subsection: {
    path: `/curriculum/${CONTENT_TYPE_SUBSECTION}/:contentId`,
    element: createElement(withEdit(Subsection)),
  },

  unit: {
    path: `/curriculum/${CONTENT_TYPE_UNIT}/:contentId`,
    element: createElement(withEdit(Unit)),
  },
  vocab: {
    path: `/curriculum/${CONTENT_TYPE_VOCABULARY}/:contentId`,
    element: createElement(withEdit(Vocabulary)),
  },

  /** ************************* TOP LEVEL CREATION ************************** */

  courseCreate: {
    path: `/curriculum/${CONTENT_TYPE_COURSE}/create`,
    element: <Course />,
  },

  sourceCreate: {
    path: `/curriculum/${CONTENT_TYPE_SOURCE}/create`,
    element: <Source />,
  },

  unitCreate: {
    path: `/curriculum/${CONTENT_TYPE_UNIT}/:contentId/create`,
    element: <Unit />,
  },

  vocabCreate: {
    path: `/curriculum/${CONTENT_TYPE_VOCABULARY}/create`,
    element: <Vocabulary />,
  },

  /** **************************** INTERACTIVES ***************************** */

  animatorCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_ANIMATOR}/create`,
    element: <Animator />,
  },

  animatorEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_ANIMATOR}/:contentId`,
    element: createElement(withEdit(Animator)),
  },

  audioCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_AUDIO}/create`,
    element: <Audio />,
  },

  audioEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_AUDIO}/:contentId`,
    element: createElement(withEdit(Audio)),
  },

  audioRecordingCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_AUDIO_RECORDING}/create`,
    element: <AudioRecording />,
  },

  audioRecordingEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_AUDIO_RECORDING}/:contentId`,
    element: createElement(withEdit(AudioRecording)),
  },

  chartCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_CHART}/create`,
    element: <Chart />,
  },

  chartEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_CHART}/:contentId`,
    element: createElement(withEdit(Chart)),
  },

  chartCompletionCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_CHART_COMPLETION}/create`,
    element: <ChartCompletion />,
  },

  chartCompletionEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_CHART_COMPLETION}/:contentId`,
    element: createElement(withEdit(ChartCompletion)),
  },

  dgoTableCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_DGO_TABLE}/create`,
    element: <DGOTable />,
  },

  dgoTableEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_DGO_TABLE}/:contentId`,
    element: createElement(withEdit(DGOTable)),
  },

  essayCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_ESSAY}/create`,
    element: <Essay />,
  },

  essayEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_ESSAY}/:contentId`,
    element: createElement(withEdit(Essay)),
  },

  evaluationRankingCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_EVALUATION_RANKING}/create`,
    element: <EvaluationRanking />,
  },

  evaluationRankingEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_EVALUATION_RANKING}/:contentId`,
    element: createElement(withEdit(EvaluationRanking)),
  },

  groupAndSortCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_GROUP_AND_SORT}/create`,
    element: <GroupAndSort />,
  },

  groupAndSortEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_GROUP_AND_SORT}/:contentId`,
    element: createElement(withEdit(GroupAndSort)),
  },

  flipbookCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_FLIPBOOK}/create`,
    element: <Flipbook />,
  },

  flipbookEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_FLIPBOOK}/:contentId`,
    element: createElement(withEdit(Flipbook)),
  },

  funFactCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_FUN_FACTS}/create`,
    element: <FunFacts />,
  },

  funFactEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_FUN_FACTS}/:contentId`,
    element: createElement(withEdit(FunFacts)),
  },

  hotspotCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_HOTSPOT}/create`,
    element: <Hotspot />,
  },

  hotspotEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_HOTSPOT}/:contentId`,
    element: createElement(withEdit(Hotspot)),
  },

  imageCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE}/create`,
    element: <Image />,
  },

  imageEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE}/:contentId`,
    element: createElement(withEdit(Image)),
  },

  imageClickerCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE_CLICKER}/create`,
    element: <ImageClicker />,
  },

  imageClickerEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE_CLICKER}/:contentId`,
    element: createElement(withEdit(ImageClicker)),
  },

  imageTitleDragCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE_TITLE_DRAG}/create`,
    element: <ImageTitleDrag />,
  },

  imageTitleDragEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE_TITLE_DRAG}/:contentId`,
    element: createElement(withEdit(ImageTitleDrag)),
  },

  imageZoomyCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE_ZOOMY}/create`,
    element: <ImageZoomy />,
  },

  imageZoomyEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_IMAGE_ZOOMY}/:contentId`,
    element: createElement(withEdit(ImageZoomy)),
  },

  linearScaleCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_LINEAR_SCALE}/create`,
    element: <LinearScale />,
  },

  linearScaleEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_LINEAR_SCALE}/:contentId`,
    element: createElement(withEdit(LinearScale)),
  },

  multipleChoiceCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_MULTIPLE_CHOICE}/create`,
    element: <MultipleChoice />,
  },

  multipleChoiceEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_MULTIPLE_CHOICE}/:contentId`,
    element: createElement(withEdit(MultipleChoice)),
  },

  pacingGuideCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_PACING_GUIDE}/create`,
    element: <PacingGuide />,
  },

  pacingGuideEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_PACING_GUIDE}/:contentId`,
    element: createElement(withEdit(PacingGuide)),
  },

  plotDiagramCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_PLOT_DIAGRAM}/create`,
    element: <PlotDiagram />,
  },

  plotDiagramEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_PLOT_DIAGRAM}/:contentId`,
    element: createElement(withEdit(PlotDiagram)),
  },

  pollCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_POLL}/create`,
    element: <Poll />,
  },

  pollEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_POLL}/:contentId`,
    element: createElement(withEdit(Poll)),
  },

  rubricCreate: {
    path: `/${CONTENT_TYPE_RUBRIC}/create`,
    element: <Rubric />,
  },

  rubricEdit: {
    path: `/${CONTENT_TYPE_RUBRIC}/:contentId`,
    element: createElement(withEdit(Rubric)),
  },

  ActivityCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_ACTIVITY}/create`,
    element: <Activity />,
  },

  ActivityEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_ACTIVITY}/:contentId`,
    element: createElement(withEdit(Activity)),
  },

  stackedSequenceCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_STACKED_SEQUENCE}/create`,
    element: <StackedSequence />,
  },

  stackedSequenceEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_STACKED_SEQUENCE}/:contentId`,
    element: createElement(withEdit(StackedSequence)),
  },

  drawToolCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_DRAW_TOOL}/create`,
    element: <DrawTool />,
  },

  drawToolEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_DRAW_TOOL}/:contentId`,
    element: createElement(withEdit(DrawTool)),
  },

  timelineCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_TIMELINE}/create`,
    element: <Timeline />,
  },

  timelineEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_TIMELINE}/:contentId`,
    element: createElement(withEdit(Timeline)),
  },

  vennDiagramCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_VENN_DIAGRAM}/create`,
    element: <VennDiagram />,
  },

  vennDiagramEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_VENN_DIAGRAM}/:contentId`,
    element: createElement(withEdit(VennDiagram)),
  },

  videoCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_VIDEO}/create`,
    element: <Video />,
  },

  videoEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_VIDEO}/:contentId`,
    element: createElement(withEdit(Video)),
  },

  wordCloudCreate: {
    path: `/interactive/${INTERACTIVE_TYPE_WORD_CLOUD}/create`,
    element: <WordCloud />,
  },

  wordCloudEdit: {
    path: `/interactive/${INTERACTIVE_TYPE_WORD_CLOUD}/:contentId`,
    element: createElement(withEdit(WordCloud)),
  },

  404: {
    path: '*',
    element: <Route404 collectionName="ContentBuilder" />,
  },
})

const routes = configToRoutes(config)

const ContentBuilderRoutes = () => (
  <ErrorBoundary moduleName="ContentBuilderRoutes">
    <Routes>{routes}</Routes>
  </ErrorBoundary>
)

ContentBuilderRoutes.propTypes = historyShape

export default ContentBuilderRoutes

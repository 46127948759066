import DeleteIcon from '@mui/icons-material/DeleteForever'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import ImageA11ySection from 'common/formControls/ImageA11ySection'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import Select from 'common/formControls/selects/Select'
import Checkbox from 'common/formControls/switches/Checkbox'
import TextField from 'common/formControls/textInputs/DebouncedTextField'
import DebouncedRichTextEdit from 'common/formControls/textInputs/RichTextEdit/DebouncedRichTextEdit'
import { set } from 'fp/objects'
import { forwardRef, useEffect, useRef } from 'react'

const ListItemRenderer = forwardRef(
  ({ disabled, index, onChange, removeItem, item, value }, ref) => {
    const valueRef = useRef(value)

    // the RichTextEdit onChange was using a stale value in the onChange
    // this fixes that
    useEffect(() => {
      valueRef.current = value
    }, [value])

    const handleChange =
      fieldName =>
      ({ target }) =>
        onChange(set(fieldName, target.value)(valueRef.current))
    const handleCheckboxChange =
      fieldName =>
      ({ target }) =>
        onChange(set(fieldName, target.checked)(value))

    return (
      <>
        <Box
          component="fieldset"
          flex={1}
          mb={2.5}>
          <Box
            component="legend"
            mb={1}>
            {`Pin ${index + 1}`}
          </Box>

          <HFAssetUploader
            label="Image"
            name={`uploadsMap.imageUrl-${index}`}
            uploadType="image"
          />

          <ImageA11ySection
            altTextName={`imageAltText-${index}`}
            altTextProps={{
              onChange: handleChange('imageAltText'),
              required: false,
              value: item.imageAltText,
            }}
            altTextValue={item.imageAltText}
            controlled={false}
            longTextName={`longTextAlternative-${index}`}
            longTextProps={{
              onChange: handleChange('longTextAlternative'),
              value: item.longTextAlternative || '',
            }}
            longTextValue={item.longTextAlternative}
            RichTextComponent={DebouncedRichTextEdit}
            TextComponent={TextField}>
            <TextField
              label="Button Description"
              name={`buttonDescription-${index}`}
              onChange={handleChange('buttonDescription')}
              value={item.buttonDescription}
            />
          </ImageA11ySection>

          <Select
            inputProps={{ ref }}
            label="Image Position"
            name={`imagePosition-${index}`}
            onChange={handleChange('imagePosition')}
            value={item.imagePosition || 'left'}>
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="top">Top</MenuItem>
          </Select>

          <TextField
            label="Title"
            name={`label-${index}`}
            onChange={handleChange('label')}
            required
            value={item.label}
          />

          <DebouncedRichTextEdit
            label="Description"
            name={`description-${index}`}
            onChange={handleChange('description')}
            required
            value={item.description}
          />

          <Box mt={2}>
            <Checkbox
              checked={Boolean(value.centered)}
              label="Centered"
              name={`centered-${index}`}
              onChange={handleCheckboxChange('centered')}
              value={item.centered}
            />
          </Box>
        </Box>
        <IconButton
          aria-label="remove label"
          disabled={disabled}
          onClick={() => removeItem(index)}>
          <DeleteIcon />
        </IconButton>
      </>
    )
  },
)

ListItemRenderer.propTypes = itemRendererProps

export default ListItemRenderer

import HFSwitch from 'common/formControls/switches/HFSwitch'
import {
  SCORING_MODE_NONE,
  SCORING_MODE_RUBRIC,
} from 'hss/ContentBuilder/consts'
import { useWatch } from 'react-hook-form'

const AllOrNothing = () => {
  const selectedMode = useWatch({ name: 'scoring.mode' })

  return selectedMode !== SCORING_MODE_RUBRIC ? (
    <HFSwitch
      disabled={selectedMode === SCORING_MODE_NONE}
      helperText="Student receives 0 points if any item is answered incorrectly"
      label="All or Nothing"
      name="scoring.allOrNothing"
    />
  ) : null
}

export default AllOrNothing

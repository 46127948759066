import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFSelect from 'common/formControls/selects/HFSelect'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import HFTextArea from 'common/formControls/textInputs/HFTextArea'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_FUN_FACTS,
} from 'core/consts'
import { arraySequence } from 'fp/arrays'
import withContent from 'hoc/withContent'
import FunFactsInteractive from 'hss/sections/contentBlocks/interactives/FunFacts'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'

const contentSubType = INTERACTIVE_TYPE_FUN_FACTS
const Interactive = FunFactsInteractive

const defaultValues = {
  facts: [],
  imagePlacement: 'top',
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const FunFacts = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-FunFacts"
    nameHelperText="This is the fun fact text to click on to show the pop up"
    nameRequired
    standardsAllowed>
    <HFTextField name="displayHeader" />
    <HFAssetUploader
      label="Image"
      name="uploadsMap.image"
      uploadType="image"
    />
    <HFTextArea
      name="imageAltText"
      required
    />
    <HFSelect
      helperText="This controls how the image takes up space within the popup"
      name="imagePlacement">
      <MenuItem value="top">Top</MenuItem>
      <MenuItem value="left">Left</MenuItem>
    </HFSelect>

    <Box mt={4}>
      {arraySequence(3).map((_, idx) => (
        <HFRichTextEditLite
          key={idx}
          label={`Fact ${idx + 1}`}
          minimized
          name={`facts.${idx}`}
        />
      ))}
    </Box>
  </Form>
)

const FunFactsWithContent = withContent(FunFacts, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default FunFactsWithContent

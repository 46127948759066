import MenuItem from '@mui/material/MenuItem'
import HFSelect from 'common/formControls/selects/HFSelect'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import {
  rteNumberOfLines,
  rteToolButtons,
} from 'common/formControls/textInputs/RichTextEdit/utils/misc'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const Content = () => {
  const formValues = useWatch()
  const { setValue } = useFormContext()
  const { rteSettings } = formValues
  const { toolButtonOptions } = rteSettings

  useEffect(() => {
    if (toolButtonOptions.all || toolButtonOptions.none) {
      const enableFeature = !!toolButtonOptions.all
      rteToolButtons.map(({ key }) =>
        setValue(`rteSettings.toolButtonOptions[${key}]`, enableFeature),
      )
    }
  }, [toolButtonOptions, setValue])

  return (
    <CollapsibleSection label="Rich Text Editor Settings">
      <HFSelect
        label="Number of Lines"
        name="rteSettings.numberOfLines">
        {rteNumberOfLines.map(line => (
          <MenuItem
            key={line.key}
            value={line.key}>
            {line.key}
          </MenuItem>
        ))}
      </HFSelect>

      <HFSwitch
        disabled={Boolean(toolButtonOptions.none)}
        helperText="Include All Features"
        label="Allow All Features"
        name="rteSettings.toolButtonOptions.all"
      />

      <HFSwitch
        disabled={Boolean(toolButtonOptions.all)}
        helperText="Exclude All Features"
        label="Disable All Features"
        name="rteSettings.toolButtonOptions.none"
      />

      {rteToolButtons.map(({ key, label }) => (
        <HFSwitch
          disabled={Boolean(toolButtonOptions.all || toolButtonOptions.none)}
          helperText={`Include ${label} Feature`}
          key={key}
          label={`Allow ${label}`}
          name={`rteSettings.toolButtonOptions[${key}]`}
        />
      ))}
    </CollapsibleSection>
  )
}

export default Content

import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import HFSelect from 'common/formControls/selects/HFSelect'
import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import { get } from 'fp/objects'
import { isNotEmptyString } from 'fp/strings'
import { fallbackTo } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import { SCORING_MODES, SCORING_MODE_RUBRIC } from 'hss/ContentBuilder/consts'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import RubricPicker from './RubricPicker'
import { SCORING_MODE_META } from './consts'

const ScoringMode = () => {
  const [isRubricLoaded, setIsRubricLoaded] = useState(false)
  const { setValue } = useFormContext()
  const [allowSubmission, contentSubType, selectedMode] = useWatch({
    name: ['allowSubmission', 'contentSubType', 'scoring.mode'],
  })
  const isRubric = selectedMode === SCORING_MODE_RUBRIC

  const call = useReduxPromise(actionTypes.CONTENT_FETCH_LIST)

  useEffect(() => {
    if (!isRubricLoaded && isRubric) {
      call({
        contentType: CONTENT_TYPE_RUBRIC,
        limit: 9999,
        queryParams: { childDepth: 2 },
      })
      setIsRubricLoaded(true)
    }
  }, [call, isRubricLoaded, isRubric])

  useEffect(() => {
    if (!isRubric) {
      setValue('scoring.rubricId', undefined)
    }
  }, [isRubric, setValue])

  return (
    <>
      <HFSelect
        disabled={!allowSubmission}
        label="Scoring Mode"
        name="scoring.mode"
        required
        rules={{
          validate: {
            filledOut: compose(
              fallbackTo('Scoring mode is required'),
              isNotEmptyString,
              get('value'),
            ),
          },
        }}
        sx={{
          maxWidth: '100%',
          '.MuiOutlinedInput-input': { flexWrap: 'wrap' },
        }}>
        {SCORING_MODES[contentSubType].map(mode => {
          const { helperText, label } = SCORING_MODE_META[mode]
          return (
            <MenuItem
              key={mode}
              sx={{ display: 'block', maxWidth: 500, whiteSpace: 'normal' }}
              value={mode}>
              <Typography
                className="detailed-menuitem-label"
                sx={{ alignSelf: 'center', mr: 1 }}
                variant="nav-item-semibold">
                {label}
              </Typography>
              <Typography
                className="detailed-menuitem-title"
                component="div"
                noWrap>
                {helperText}
              </Typography>
            </MenuItem>
          )
        })}
      </HFSelect>

      {Boolean(isRubric) && (
        <RubricPicker
          label="Rubric"
          loading={!isRubricLoaded}
          name="scoring.rubricId"
          required
          rules={{
            validate: {
              filledOut: compose(
                fallbackTo('Rubric is required'),
                isNotEmptyString,
                get('value'),
              ),
            },
          }}
          sx={{ mb: 1 }}
        />
      )}
    </>
  )
}

ScoringMode.propTypes = {}

export default ScoringMode

import Typography from '@mui/material/Typography'
import Checkbox from 'common/formControls/switches/Checkbox'
import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import { isDefined } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import {
  SCORING_MODE_NONE,
  SCORING_MODE_RUBRIC,
} from 'hss/ContentBuilder/consts'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const MaxScore = () => {
  const [selectedMode, maxScoreOverride] = useWatch({
    name: ['scoring.mode', 'scoring.maxScoreOverride'],
  })
  const { setValue } = useFormContext()
  const [overrideEnabled, setOverrideEnabled] = useToggleState(
    isDefined(maxScoreOverride),
  )
  const scoringModeAllowsOverride =
    selectedMode !== SCORING_MODE_NONE && selectedMode !== SCORING_MODE_RUBRIC
  const handleCheckboxChange = ({ target: { checked } }) => {
    setOverrideEnabled(checked)
  }

  useEffect(() => {
    // Using `undefined` to clear the value and avoid console warnings about null value prop on `input`
    if (!overrideEnabled && maxScoreOverride !== undefined) {
      setValue('scoring.maxScoreOverride', undefined, { shouldDirty: true })
    }
  }, [maxScoreOverride, overrideEnabled, setValue])

  useEffect(() => {
    if (!scoringModeAllowsOverride && overrideEnabled) {
      setOverrideEnabled(false)
    }
  }, [overrideEnabled, scoringModeAllowsOverride, setOverrideEnabled])

  return (
    <>
      <Checkbox
        checked={overrideEnabled}
        disabled={!scoringModeAllowsOverride}
        label={
          <Typography
            variant={scoringModeAllowsOverride ? 'body1' : 'greyscale'}>
            Override default maximum point value
          </Typography>
        }
        onChange={handleCheckboxChange}
        value={overrideEnabled}
      />
      {Boolean(overrideEnabled) && (
        <HFNumberField
          label="Maximum Points"
          min={0}
          name="scoring.maxScoreOverride"
          required
        />
      )}
    </>
  )
}

export default MaxScore

import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_SUBTYPE_SKILLS_ROLLUP,
  CONTENT_SUBTYPE_STANDARDS_ROLLUP,
  CONTENT_SUBTYPE_VOCAB_ROLLUP,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import withContent from 'hoc/withContent'
import { formPropsShape } from 'hss/ContentBuilder/utils'
import { useSelector } from 'react-redux'
import { getAnyAncestorIsTe } from 'selectors/contentViewer'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'
import ImageControls from '../ImageControls'

const Subsection = formProps => {
  const {
    content,
    content: { contentSubType },
  } = formProps
  const hasTeAncestor = useSelector(getAnyAncestorIsTe({ content }))

  const childContentTypes = [
    CONTENT_TYPE_BLOCK,
    hasTeAncestor || contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY
      ? null
      : CONTENT_TYPE_SCAFFOLD,
  ].filter(Boolean)

  const childContentSubTypes = [
    CONTENT_SUBTYPE_SKILLS_ROLLUP,
    CONTENT_SUBTYPE_STANDARDS_ROLLUP,
    CONTENT_SUBTYPE_VOCAB_ROLLUP,
    null, // to allow for plain blocks
  ]

  return (
    <CurriculumForm
      assetCodeRequired
      contentSubType={contentSubType}
      formProps={formProps}
      tagsAllowed
      vocabPhrasesAllowed={!hasTeAncestor}>
      <ImageControls
        captionPosition="bottom"
        formProps={formProps}
        showMetaData
        thumb={false}
      />

      <ChildListSection
        contentSubTypes={childContentSubTypes}
        contentTypes={childContentTypes}
        formProps={formProps}
        name="children"
        title="Content Blocks"
      />
    </CurriculumForm>
  )
}

Subsection.propTypes = formPropsShape.isRequired

const SubsectionWithContent = withContent(Subsection, {
  contentType: CONTENT_TYPE_SUBSECTION,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default SubsectionWithContent

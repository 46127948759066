import HFTextField from 'common/formControls/textInputs/HFTextField'
import AppBusy from 'common/indicators/AppBusy'
import {
  CONTENT_SUBTYPE_UNIT_NORMAL,
  CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_UNIT,
} from 'core/consts'
import { hasProperty } from 'fp/objects'
import { suffix } from 'fp/strings'
import withContent from 'hoc/withContent'
import { formProps as formPropsShape } from 'hss/ContentBuilder/utils'
import { useMemo } from 'react'
import { Titled } from 'react-titled'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'
import ImageControls from '../ImageControls'

const Course = originalProps => {
  const formProps = useMemo(
    () => (hasProperty('content')(originalProps) ? originalProps : undefined),
    // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: fix this!!  causes extra renders
    [originalProps],
  )

  return formProps ? (
    <Titled
      title={suffix(`: ${originalProps.content ? 'Edit' : 'New'} Course`)}>
      <CurriculumForm
        formProps={formProps}
        omitAssetCode
        tagsAllowed>
        <HFTextField name="subtitle" />
        <HFTextField name="eyebrow" />
        <ChildListSection
          contentSubTypes={[
            CONTENT_SUBTYPE_UNIT_NORMAL,
            CONTENT_SUBTYPE_UNIT_SUPPLEMENTAL,
            null,
          ]}
          contentTypes={[CONTENT_TYPE_UNIT]}
          formProps={formProps}
          name="children"
          title="Units"
        />

        <ImageControls formProps={formProps} />
      </CurriculumForm>
    </Titled>
  ) : (
    <AppBusy />
  )
}

Course.propTypes = formPropsShape

const CourseWithContent = withContent(Course, {
  contentType: CONTENT_TYPE_COURSE,
  forceRefresh: true,
  queryParams: { childDepth: 2 },
})

export default CourseWithContent

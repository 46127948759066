import HFJsonTextArea, {
  JSON_TYPE_OBJECT,
  basicJsonValidator,
} from 'common/formControls/textInputs/HFJsonTextArea'
import Headline from 'common/text/Headline'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_CHART } from 'core/consts'
import { isString, maybeParseJSON } from 'fp/strings'
import { isDefined } from 'fp/utils'
import withContent from 'hoc/withContent'
import ChartInteractive from 'hss/sections/contentBlocks/interactives/Chart'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'

const contentSubType = INTERACTIVE_TYPE_CHART
const Interactive = ChartInteractive

const defaultValues = {
  config: '{}',
}

const jsonType = JSON_TYPE_OBJECT

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

// for now it's just a freeform JSON config that can go in here
const Chart = formProps => (
  <Form
    {...{
      contentSubType,
      defaultValues,
      formProps,
      previewButton,
      framelessAllowed: true,
    }}
    captionAllowed
    floatSupported
    name="Interactive-Chart"
    standardsAllowed>
    <Headline
      mb={2}
      mt={4}
      title="Chart JSON">
      <HFJsonTextArea
        jsonType={jsonType}
        label="Config"
        name="config"
        required
        rules={{
          validate: ({ required, value }) => {
            const config = maybeParseJSON(value)
            const trueOrErrorMessage = basicJsonValidator({
              required,
              value,
              jsonType,
            })
            const valid = isDefined(config.charts) || isDefined(config.chart)

            if (isString(trueOrErrorMessage)) return trueOrErrorMessage

            return required
              ? valid || 'A chart:{} or charts:[] property must be defined'
              : true
          },
        }}
      />
    </Headline>
  </Form>
)

const ChartWithContent = withContent(Chart, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default ChartWithContent

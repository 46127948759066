import { maxFormWidth } from 'common/formControls/Form/utils'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
} from 'core/consts'
import { get, set } from 'fp/objects'
import withContent from 'hoc/withContent'
import ImageClickerInteractive from 'hss/sections/contentBlocks/interactives/ImageClicker'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_IMAGE_CLICKER
const Interactive = ImageClickerInteractive
const defaultValues = {
  darkBackdropImage: false,
  imageAltText: '',
  messages: [],
  uploadsMap: {},
}
const inputStyle = { maxWidth: maxFormWidth, display: 'block' }
const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const ImageClicker = formProps => (
  <Form
    fullWidth
    inputStyle={inputStyle}
    name="Interactive-ImageClicker"
    standardsAllowed
    {...{
      contentSubType,
      defaultValues: set(
        'uploadsMap',
        get('content.uploadsMap')(formProps),
      )(defaultValues),
      formProps,
      previewButton,
    }}>
    <Content />
  </Form>
)

const ImageClickerWithContent = withContent(ImageClicker, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default ImageClickerWithContent

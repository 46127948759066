import HFSwitch from 'common/formControls/switches/HFSwitch'
import Headline from 'common/text/Headline'
import PlotDiagramContent from 'hss/sections/contentBlocks/interactives/PlotDiagram/Content'
import { useFormContext, useWatch } from 'react-hook-form'

const Content = () => {
  const formValues = useWatch()
  const { setValue } = useFormContext()

  const updateParentForm = updatedData => {
    const key = Object.keys(updatedData)[0]
    setValue(key, updatedData[key], { shouldDirty: true })
  }

  return (
    <Headline
      my={4}
      title="Plot Diagram">
      <HFSwitch
        helperText="Display only, do not allow editing"
        name="readOnly"
      />
      <PlotDiagramContent
        interactiveData={formValues}
        onSave={updateParentForm}
        readOnly={false}
      />
    </Headline>
  )
}

export default Content

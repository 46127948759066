import Button from '@mui/material/Button'
import { HFRichTextEditLite } from 'common/formControls/textInputs/HFRichTextEdit'
import { isEmptyString } from 'fp/strings'
import { Plus } from 'react-feather'
import { useFormContext, useWatch } from 'react-hook-form'

const fieldName = 'pullQuote'

const PullQuote = () => {
  const { setValue } = useFormContext()
  const pullQuote = useWatch({ name: fieldName })

  const handleAdd = () => {
    setValue(
      fieldName,
      `
      <blockquote data-typography="pullQuote">Quote</blockquote>
      <p data-typography="credit">credit</p>
    `.trim(),
    )
  }

  return isEmptyString(pullQuote) ? (
    <Button
      onClick={handleAdd}
      size="small"
      startIcon={<Plus />}
      sx={{ mt: 3 }}>
      Provide Pull Quote
    </Button>
  ) : (
    <HFRichTextEditLite
      label="Pull Quote"
      minimized
      name={fieldName}
    />
  )
}

export default PullQuote

import Button from '@mui/material/Button'
import { AdvancedListPrime } from 'common/formControls/lists/AdvancedList'
import { listShape } from 'common/formControls/lists/BasicList'
import { get, set } from 'fp/objects'
import ListItemRenderer from './ListItemRenderer'

const List = props => (
  <AdvancedListPrime
    getter={get('label')}
    ItemRenderer={ListItemRenderer}
    setter={(item, value) => set('label', value)(item)}
    {...props}>
    {({ addItem, disabled }) => (
      <Button
        disabled={disabled}
        onClick={() =>
          addItem({
            calloutCoord: '20,20',
            label: '',
            labelCoord: '30,30',
          })
        }
        size="small"
        style={{ marginTop: '1rem' }}>
        add
      </Button>
    )}
  </AdvancedListPrime>
)

List.propTypes = listShape

export default List

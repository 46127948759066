import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import Dialog from 'common/dialogs/Dialog'
import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import { componentShape } from 'core/shapes'
import { get, pick, set } from 'fp/objects'
import { isDefined } from 'fp/utils'
import withDetachedInteraction from 'hoc/withDetachedInteraction'
import useContent from 'hooks/useContent'
import Activity from 'hss/sections/contentBlocks/interactives/Activity'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getFlattenedChildren } from 'selectors/content'

const rubricDataProps = [
  'description',
  'maxScore',
  'startAtZero',
  'standardIds',
  'standards',
]

const withRubric = (WrappedComponent, { rubricId }) => {
  const Enhanced = props => {
    const rubricSelectorOptions = {
      contentType: CONTENT_TYPE_RUBRIC,
      contentId: rubricId,
    }
    const rubric = compose(
      // getFlattenedChildren will work, since rubrics don't have grandchildren
      set(
        'children',
        compose(useSelector, getFlattenedChildren)(rubricSelectorOptions),
      ),
      useContent,
      set('queryParams', { childDepth: 2 }),
    )(rubricSelectorOptions)

    return <WrappedComponent {...{ ...props, rubric, contentId: rubricId }} />
  }
  return Enhanced
}

const Preview = ({
  additionalInteractiveProps,
  formValues,
  Interactive,
  open,
  ...rest
}) => {
  // Rubrics are used in the context of an interactive e.g. Activity, Essay, etc.
  // Use an ad-hoc Activity interactive to preview a Rubric.
  const isRubricContent = Interactive === Rubric
  const editingRubric = isRubricContent && formValues
  const DetachedInteractive = withDetachedInteraction(
    isRubricContent ? Activity : Interactive,
  )

  // We may need to make sure the Rubric is actually loaded first.
  const rubricId = isRubricContent
    ? get('id')(additionalInteractiveProps || formValues || {})
    : get('scoring.rubricId')(formValues || {})

  const InteractiveToPreview =
    isDefined(rubricId) && !editingRubric
      ? withRubric(DetachedInteractive, { rubricId })
      : DetachedInteractive

  const previewFormValues = editingRubric
    ? {
        contentType: CONTENT_TYPE_RUBRIC,
        // Rubric component will expect the rubric to be a prop of the ad-hoc Activity interactive mentioned above.
        rubric: {
          data: pick(rubricDataProps)(formValues),
          children: formValues.children,
        },
      }
    : formValues

  return (
    <Dialog
      fullScreen
      open={open}
      showCloseButton
      title="Preview"
      TitleProps={{ hr: true }}
      {...rest}>
      <Box
        component={DialogContent}
        mb={3}
        textAlign="left">
        {Boolean(open) && (
          <InteractiveToPreview
            {...previewFormValues}
            {...additionalInteractiveProps}
            previewing
          />
        )}
      </Box>
    </Dialog>
  )
}

Preview.propTypes = {
  additionalInteractiveProps: PropTypes.object,
  formValues: PropTypes.object,
  Interactive: componentShape.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default Preview

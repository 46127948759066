import ClearIcon from '@mui/icons-material/Clear'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import TextField from 'common/formControls/textInputs/TextField'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import { RubricStandardsPicker } from 'common/standards/StandardsPickers'
import { isEmptyString } from 'fp/strings'
import { isDefined } from 'fp/utils'
import Options from 'hss/ContentBuilder/interactives/Rubric/Options'
import Callouts from 'hss/ContentBuilder/interactives/Rubric/Options/Callouts'
import { forwardRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const ListItemRenderer = forwardRef(
  ({ allowDeletion, disabled, index, removeItem }, ref) => {
    const {
      formState: { errors },
      setValue,
    } = useFormContext()
    const namePrefix = `children.${index}.`
    const dataField = `${namePrefix}data`
    const nameField = `${dataField}.title`
    const optionsField = `${dataField}.options`
    const ratingName = useWatch({ name: nameField })

    const handleChange = ({ target }) => {
      setValue(nameField, target.value)
    }

    return (
      <>
        <Stack width="100%">
          <Box
            component="fieldset"
            flex={1}>
            <CollapsibleSection
              dataSubvariant="feature-paragraph"
              empty={isEmptyString(ratingName)}
              hasErrors={isDefined(errors.caption)}
              label={ratingName}>
              <TextField
                label="Title"
                name={nameField}
                onChange={handleChange}
                ref={ref}
                value={ratingName}
              />
              <Callouts
                index={index}
                label=""
                ListRenderer={Options}
                name={optionsField}
              />
              <RubricStandardsPicker
                name={`${namePrefix}applicationStandards`}
                style={{ marginTop: '3rem' }}
                valueField={`${namePrefix}applicationStandardIds`}
              />
            </CollapsibleSection>
          </Box>
        </Stack>
        {Boolean(allowDeletion) && (
          <Tooltip title="Delete">
            <IconButton
              data-testid={`remove-row-btn-${index}`}
              disabled={disabled}
              onClick={() => removeItem(index)}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    )
  },
)

ListItemRenderer.propTypes = itemRendererProps

export default ListItemRenderer

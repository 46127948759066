import { styled } from '@mui/material/styles'
import Headline from 'common/text/Headline'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { formPropsShape } from '../utils'
import ChildList from './ChildList'
import { allowedContentTypesShape } from './ChildListNewItem'

const ChildListSection = styled(
  props => {
    const {
      binaryMode = false,
      children,
      className,
      contentSubTypes = [],
      contentTypes,
      formProps,
      isDBI = false,
      name,
      title,
    } = props

    const tabbedVal = useWatch({ name: 'tabbed' })
    const tabbed = Boolean(tabbedVal)

    return formProps.content ? (
      <>
        <Headline
          gutterBottom
          mt={3}
          title={tabbed ? 'Tabs' : title}>
          <ChildList
            {...{
              binaryMode,
              className,
              contentTypes,
              formProps,
              isDBI,
              name,
              contentSubTypes,
              tabbed,
            }}
          />

          {children}
        </Headline>

        {!binaryMode && <hr />}
      </>
    ) : null
  },
  { name: 'Curriculum-ChildList' },
)(({ theme: { palette } }) => ({
  '.MuiBox-root': {
    flex: 1,
    minWidth: 0,

    '.child-link': {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    '.confirm-deletion': { color: palette.error.main },
  },
}))

ChildListSection.propTypes = {
  binaryMode: PropTypes.bool,
  contentTypes: allowedContentTypesShape.isRequired,
  formProps: formPropsShape.isRequired,
  isDBI: PropTypes.bool,
  name: PropTypes.string.isRequired,
  contentSubTypes: allowedContentTypesShape,
  title: PropTypes.string.isRequired,
}

export default ChildListSection

import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_FLIPBOOK,
} from 'core/consts'
import withContent from 'hoc/withContent'
import FlipbookInteractive from 'hss/sections/contentBlocks/interactives/Flipbook'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_FLIPBOOK
const Interactive = FlipbookInteractive
const defaultValues = {
  offsetRadius: 5,
  showNavigation: true,
  transition: 'gentle',
  slides: '',
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const Flipbook = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    captionAllowed
    framelessAllowed={false}
    name="Interactive-FlipBook">
    <Content />
  </Form>
)

const FlipbookWithContent = withContent(Flipbook, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default FlipbookWithContent

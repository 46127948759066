import DeleteIcon from '@mui/icons-material/DeleteForever'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import AdvancedList, {
  itemRendererProps,
} from 'common/formControls/lists/AdvancedList'
import { nextValue } from 'fp/arrays'
import { set } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { identity, isDefined } from 'fp/utils'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { formPropsShape } from '../../utils'

const AddNewItem = props => {
  const { addItem, defaultItem, disabled, items } = props
  const id = nextValue()(items)
  const handleAdd = () => {
    addItem({ id, ...defaultItem })
  }

  return (
    <Button
      disabled={disabled}
      onClick={handleAdd}
      size="small">
      Add
    </Button>
  )
}
AddNewItem.propTypes = {
  addItem: PropTypes.func.isRequired,
  defaultItem: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
}

const ItemRenderer = forwardRef((props, ref) => {
  const { InputRenderer, index, onChange, removeItem, value } = props

  const handleRemove = () => {
    removeItem(index)
  }

  return (
    <>
      <InputRenderer
        inputProps={{ ref }}
        onChange={({ target }) => onChange(target.value)}
        sx={{
          '.DraftEditor-root': {
            minHeight: '100px !important',
            maxHeight: '100px !important',
          },
        }}
        value={value.value}
      />

      <IconButton onClick={handleRemove}>
        <DeleteIcon />
      </IconButton>
    </>
  )
})
ItemRenderer.propTypes = itemRendererProps

const List = ({
  className,
  defaultItem = { value: '' },
  formProps,
  label,
  maximumItems,
  name,
  ...rest
}) => {
  const rules = {
    validate: ({ value }) =>
      value.filter(item => isEmptyString(item.value)).length
        ? 'Fill in the item content or remove it from the list'
        : true,
  }

  return (
    <AdvancedList
      allowReordering={false}
      getter={identity}
      noLabel
      setter={(item, value) => set('value', value)(item)}
      {...{ className, ItemRenderer, name, label, rules, ...formProps }}
      {...rest}>
      {childProps => (
        <AddNewItem
          label={label}
          {...childProps}
          defaultItem={defaultItem}
          disabled={
            isDefined(maximumItems) && childProps.items.length >= maximumItems
          }
        />
      )}
    </AdvancedList>
  )
}

List.propTypes = {
  defaultItem: PropTypes.object,
  formProps: formPropsShape.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maximumItems: PropTypes.number,
}

export default List

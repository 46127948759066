import { HFDebouncedTextField } from 'common/formControls/textInputs/HFTextField'
import { CONTENT_TYPE_VOCABULARY } from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import { buildUrl } from 'fp/internet'
import useApiFromEffect from 'hooks/useApiFromEffect'
import { useCallback } from 'react'
import { useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'

const VocabTermField = () => {
  const callApi = useApiFromEffect()
  const { contentId } = useParams()
  const currentValue = useWatch({ name: 'name' })

  const validate = useCallback(
    async ({ value }) => {
      if (value === currentValue) return
      const search = compose(
        alter.set.where('contentType').is(CONTENT_TYPE_VOCABULARY),
        alter.set.where('name').is(value),
      )(assert())

      const result = await callApi({
        url: buildUrl(restEndpoint.content, { search }, false),
      })

      return result.isRightValue &&
        result.value.data.length &&
        result.value.data[0].id !== contentId
        ? 'Vocabulary term already exists'
        : true
    },
    [callApi, contentId, currentValue],
  )

  return (
    <HFDebouncedTextField
      autoFocus
      label="Term"
      name="name"
      required
      rules={{ validate }}
    />
  )
}

export default VocabTermField

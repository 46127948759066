import AppBusy from 'common/indicators/AppBusy'
import { CONTENT_TYPE_CHAPTER, CONTENT_TYPE_UNIT } from 'core/consts'
import withContent from 'hoc/withContent'
import ChildListSection from '../ChildListSection'
import CurriculumForm from '../CurriculumForm'
import UnitContentSubTypePicker from '../UnitContentSubTypePicker'

const Unit = formProps =>
  formProps.content ? (
    <CurriculumForm
      captionAllowed
      captionRequired
      formProps={formProps}
      nameRequired={false}
      omitAssetCode
      tagsAllowed>
      <UnitContentSubTypePicker />
      <ChildListSection
        contentTypes={[CONTENT_TYPE_CHAPTER]}
        formProps={formProps}
        name="children"
        title="Chapters"
      />
    </CurriculumForm>
  ) : (
    <AppBusy />
  )

Unit.propTypes = {}

const UnitWithContent = withContent(Unit, {
  contentType: CONTENT_TYPE_UNIT,
  forceRefresh: true,
  queryParams: { childDepth: 4 },
})

export default UnitWithContent

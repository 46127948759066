import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import HFSelect from 'common/formControls/selects/HFSelect'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFAssetCodeField from 'common/formControls/textInputs/HFAssetCodeField'
import HFCollapsedRTE from 'common/formControls/textInputs/HFCollapsedRTE'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import DisplayChildStandards from 'common/standards/DisplayChildStandards'
import Standards from 'common/standards/Standards'
import TagsPicker from 'common/tags/TagsPicker'
import Headline from 'common/text/Headline'
import DisplayChildVocabulary from 'common/vocabulary/DisplayChildVocabulary'
import Vocabulary from 'common/vocabulary/Vocabulary'
import {
  CONTENT_TYPE_SUBSECTION,
  MANUAL_SUBMITTABLE_INTERACTIVES,
  TEACHER_EDITION_PREFIX,
} from 'core/consts'
import { componentShape, contentShape } from 'core/shapes'
import { map } from 'fp/arrays'
import { equals, get } from 'fp/objects'
import { isDefined, when } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import useIsMounted from 'hooks/useIsMounted'
import Territories from 'hss/ContentBuilder/Curriculum/Territories'
import { SCORING_MODES, SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { compose } from 'redux'
import deepClone from 'rfdc/default'
import Caption from './Caption'
import Frameless from './Frameless'
import InteractiveInstructions from './InteractiveInstructions'
import Scoring from './Scoring'
import StudentInstructions from './StudentInstructions'
import { builderContext } from './builderContext'

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: sure
const Content = props => {
  const {
    assetCodeRequired = true,
    captionAllowed = true,
    captionOnSideAllowed = true,
    captionRequired = false,
    children,
    content,
    contentSubType,
    currentContentType = 'se',
    framelessAllowed = true,
    inputStyle,
    interactiveInstructionsAllowed = true,
    nameHelperText,
    nameRequired = false,
    omitAssetCode = false,
    omitName = false,
    showCaptionPosition = true,
    showVocabRollup = false,
    standardsAllowed = false,
    studentInstructionsAllowed = true,
    tagsAllowed = false,
    variants,
    vocabPhrasesAllowed = true,
  } = props

  const isTe = currentContentType === 'te' // do not confuse with content.teacherEdition (!)
  const { disabled } = useContext(additionalContext)
  const isMounted = useIsMounted()
  const { setCurrentValues, setDisabled, setGetTrigger, setIsValid } =
    useContext(builderContext)
  const {
    formState: { isValid },
    // getFieldState,
    getValues,
    setValue,
    trigger,
  } = useFormContext()
  const showSubmitChoice =
    MANUAL_SUBMITTABLE_INTERACTIVES.includes(contentSubType)

  const all = useWatch({})
  const { readOnly } = all
  const [allowSubmission, variant] = useWatch({
    name: ['allowSubmission', 'variant'],
  })

  useDeepCompareEffect(() => {
    when(isMounted, setCurrentValues, deepClone(all))
  }, [all, isMounted, setCurrentValues])

  useEffect(() => {
    when(isMounted, setIsValid, isValid)
  }, [isMounted, isValid, setIsValid])

  useEffect(() => {
    when(isMounted, setDisabled, disabled)
  }, [disabled, isMounted, setDisabled])

  useEffect(() => {
    when(isMounted, setGetTrigger, () => trigger)
  }, [isMounted, setGetTrigger, trigger])

  useEffect(() => {
    when(
      isMounted && captionAllowed && !getValues('captionPosition'),
      setValue,
      'captionPosition',
      'bottom',
    )
  }, [captionAllowed, getValues, isMounted, setValue])

  useEffect(() => {
    when(
      !allowSubmission &&
        (!SCORING_MODES[contentSubType] ||
          SCORING_MODES[contentSubType].includes(SCORING_MODE_NONE)),
      setValue,
      'scoring.mode',
      SCORING_MODE_NONE,
    )
  }, [allowSubmission, contentSubType, setValue])

  useEffect(() => {
    if (variants) {
      const { defaults = {} } = variants[variant] || {}

      Object.entries(defaults).forEach(([fieldName, defaultValue]) => {
        const currentValue = getValues(fieldName)

        const possibleDefaultValues = compose(
          map(get(`defaults.${fieldName}`)),
          Object.values,
        )(variants)

        if (!currentValue || possibleDefaultValues.some(equals(currentValue))) {
          setValue(fieldName, defaultValue, { shouldDirty: false })
        }
      })
    }
  }, [getValues, setValue, variant, variants])

  useEffect(() => {
    when(readOnly, setValue, 'allowSubmission', false)
  }, [readOnly, setValue])

  return (
    <ErrorBoundary moduleName="Builder-Form-Content">
      {!isTe && (
        <>
          {Boolean(assetCodeRequired) && (
            <HFAssetCodeField
              excludeContentId={content?.id}
              mandatory={assetCodeRequired}
              required={assetCodeRequired}
              style={inputStyle}
            />
          )}

          {!omitName && (
            <HFTextField
              autoFocus={!assetCodeRequired}
              helperText={nameHelperText}
              label={`Name / Title${nameRequired ? '' : ' (optional)'}`}
              name="name"
              required={nameRequired}
              style={inputStyle}
            />
          )}

          {Boolean(!(assetCodeRequired || omitAssetCode)) && (
            <HFAssetCodeField
              autoFocus={false}
              excludeContentId={content?.id}
              style={inputStyle}
            />
          )}
        </>
      )}

      <ContainedFormContent pb={3}>
        <Territories />

        {Boolean(interactiveInstructionsAllowed) && <InteractiveInstructions />}

        {Boolean(studentInstructionsAllowed) && <StudentInstructions />}

        {Boolean(isDefined(variants)) && (
          <Box mt={8}>
            <HFSelect name="variant">
              {Object.entries(variants).map(([key, { displayName }]) => (
                <MenuItem
                  key={key}
                  value={key}>
                  {displayName}
                </MenuItem>
              ))}
            </HFSelect>
          </Box>
        )}
      </ContainedFormContent>

      {children}

      <ContainedFormContent pb={3}>
        {Boolean(framelessAllowed) && <Frameless />}

        {Boolean(showSubmitChoice) && (
          <HFSwitch
            disabled={readOnly}
            helperText="Provide a submit button to allow student submission."
            name="allowSubmission"
          />
        )}

        <Scoring />

        {Boolean(vocabPhrasesAllowed) && (
          <>
            {Boolean(isTe) && (
              <Vocabulary namePrefix={TEACHER_EDITION_PREFIX} />
            )}
            {!isTe && <Vocabulary />}
          </>
        )}

        {Boolean(tagsAllowed) && (
          <Headline
            sx={{ marginTop: 2 }}
            title="Tags">
            {Boolean(isTe) && (
              <TagsPicker
                label=""
                name={`${TEACHER_EDITION_PREFIX}tags`}
                sx={{ marginTop: 0 }}
              />
            )}
            {!isTe && (
              <TagsPicker
                label=""
                sx={{ marginTop: 0 }}
              />
            )}
          </Headline>
        )}

        {Boolean(standardsAllowed) && (
          <>
            {Boolean(isTe) && <Standards namePrefix={TEACHER_EDITION_PREFIX} />}
            {!isTe && <Standards />}
          </>
        )}

        {Boolean(showVocabRollup) && (
          <DisplayChildVocabulary content={content} />
        )}

        {content?.contentType === CONTENT_TYPE_SUBSECTION && (
          <>
            {!standardsAllowed && (
              <Headline
                hr
                title="Standards"
              />
            )}
            <DisplayChildStandards content={content} />
          </>
        )}

        {Boolean((captionAllowed && !isTe) || captionRequired) && (
          <Caption
            captionOnSideAllowed={captionOnSideAllowed}
            required={captionRequired}
            showCaptionPosition={showCaptionPosition}
          />
        )}

        {Boolean(isTe) && (
          <HFCollapsedRTE name={`${TEACHER_EDITION_PREFIX}notes`} />
        )}
        {!isTe && <HFCollapsedRTE name="notes" />}
      </ContainedFormContent>
    </ErrorBoundary>
  )
}

Content.propTypes = {
  assetCodeRequired: PropTypes.bool,
  captionAllowed: PropTypes.bool,
  captionOnSideAllowed: PropTypes.bool,
  captionRequired: PropTypes.bool,
  children: componentShape,
  content: contentShape,
  contentSubType: PropTypes.string,
  currentContentType: PropTypes.oneOf(['se', 'te']),
  framelessAllowed: PropTypes.bool,
  inputStyle: PropTypes.object,
  interactiveInstructionsAllowed: PropTypes.bool,
  nameHelperText: PropTypes.node,
  nameRequired: PropTypes.bool,
  omitAssetCode: PropTypes.bool,
  omitName: PropTypes.bool,
  showCaptionPosition: PropTypes.bool,
  showVocabRollup: PropTypes.bool,
  standardsAllowed: PropTypes.bool,
  studentInstructionsAllowed: PropTypes.bool,
  tagsAllowed: PropTypes.bool,
  variants: PropTypes.objectOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      defaultValues: PropTypes.objectOf(PropTypes.any),
    }),
  ),
  vocabPhrasesAllowed: PropTypes.bool,
}

export default Content

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import Select from 'common/formControls/selects/Select'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'

const cellTypes = [
  { contentType: 'text', isStatic: true, label: 'Display', value: 'text' },
  {
    contentType: 'text',
    isStatic: false,
    label: 'Drag and Drop',
    value: 'text-drag',
  },
  {
    contentType: 'input',
    isStatic: true,
    isRichInput: false,
    label: 'Student input',
    value: 'input',
  },
  {
    contentType: 'input',
    isStatic: true,
    isRichInput: true,
    label: 'Student input (Rich text)',
    value: 'input-rte',
  },
  {
    contentType: 'image',
    isStatic: true,
    label: 'Image - Display',
    value: 'image',
  },
  {
    contentType: 'image',
    isStatic: false,
    label: 'Image - Drag and Drop',
    value: 'image-drag',
  },
]

const Cell = ({ itemIdx }) => {
  const { setValue } = useFormContext()
  const item = useWatch({ name: `items.${itemIdx}` })
  const handleContentTypeChange = ({ target: { value } }) => {
    const attributes = value.split('-')
    setValue(`items.${itemIdx}`, {
      ...item,
      contentType: attributes[0],
      isStatic: attributes.indexOf('drag') < 0,
      isRichInput: attributes.indexOf('rte') > -1,
    })
  }

  return (
    <Box
      component="td"
      sx={{ border: '1px dashed', borderColor: 'border.0', padding: 1 }}>
      <Stack>
        <Select
          label="Content Type"
          name={`items.${itemIdx}.contentType`}
          onChange={handleContentTypeChange}
          required
          value={`${item.contentType}${item.isStatic ? '' : '-drag'}${item.isRichInput ? '-rte' : ''}`}>
          {cellTypes.map(({ label, value }) => (
            <MenuItem
              key={label}
              value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {item.contentType === 'image' ? (
          <>
            <HFAssetUploader
              label="Image"
              name={`uploadsMap.${item.id}`}
              required
              uploadType="image"
            />
            <HFTextField
              label="Image Description"
              name={`items.${itemIdx}.imageDescription`}
            />
          </>
        ) : (
          <HFRichTextEdit
            label="Text"
            minimized
            name={`items.${itemIdx}.value`}
            placeholder={`${item.contentType === 'input' ? 'Recommended' : ''} Text`}
            rows={2}
            variant="standard"
          />
        )}
      </Stack>
    </Box>
  )
}

Cell.propTypes = {
  itemIdx: PropTypes.number.isRequired,
}

export default Cell

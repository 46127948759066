import Button from '@mui/material/Button'
import { AdvancedListPrime } from 'common/formControls/lists/AdvancedList'
import { listShape } from 'common/formControls/lists/BasicList'
import { map } from 'fp/arrays'
import { split, toInt } from 'fp/strings'
import { identity, takeSecond } from 'fp/utils'
import { compose } from 'redux'
import ListItemRenderer from './ListItemRenderer'

const getNextCoord = (items, fieldName, defaultCoord) => {
  if (!items?.length) return defaultCoord

  const [x, y] = compose(
    map(toInt),
    split(','),
  )(items[items.length - 1][fieldName])

  return `${x + 15},${y}`
}

const List = props => (
  <AdvancedListPrime
    getter={identity}
    hideLabel
    ItemRenderer={ListItemRenderer}
    setter={takeSecond}
    {...props}>
    {({ disabled, addItem, items }) => (
      <Button
        disabled={disabled}
        onClick={() => {
          addItem({
            buttonDescription: '',
            calloutCoord: getNextCoord(items, 'calloutCoord', '20, 20'),
            centered: true,
            description: '',
            imageAltText: '',
            imagePosition: 'top',
            label: '',
            labelCoord: getNextCoord(items, 'labelCoord', '30, 30'),
            longTextAlternative: '',
            uploadsMap: {},
          })
        }}
        size="small"
        style={{ marginTop: '1rem', marginBottom: '4rem' }}>
        add
      </Button>
    )}
  </AdvancedListPrime>
)

List.propTypes = listShape

export default List

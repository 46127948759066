import HFSwitch from 'common/formControls/switches/HFSwitch'
import Headline from 'common/text/Headline'
import VennDiagramContent from 'hss/sections/contentBlocks/interactives/VennDiagram/Content'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const Content = () => {
  const formValues = useWatch()
  const { setValue } = useFormContext()
  const updateParentForm = (key, value) => {
    setValue(key, value, { shouldDirty: true })
  }

  useEffect(() => {
    if (formValues.readOnly) {
      setValue('studentHeadlineEditing', false)
      setValue('studentBottomCircle', false)
    }
  }, [formValues.readOnly, setValue])

  return (
    <Headline
      my={4}
      title="Display Options">
      <HFSwitch
        helperText="Display only, do not allow editing."
        name="readOnly"
      />
      <HFSwitch
        disabled={formValues.readOnly}
        helperText="Allow students to edit headlines."
        name="studentHeadlineEditing"
      />
      <HFSwitch
        disabled={formValues.readOnly}
        helperText="Allow students to increase size to support three circles."
        name="studentBottomCircle"
      />
      <VennDiagramContent
        {...formValues}
        hideSampleResponse={false}
        onChange={updateParentForm}
        readOnly={false}
        studentBottomCircle
        studentHeadlineEditing
      />
    </Headline>
  )
}

export default Content

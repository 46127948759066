import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
} from 'core/consts'
import withContent from 'hoc/withContent'
import AudioRecordingInteractive from 'hss/sections/contentBlocks/interactives/AudioRecording'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'

const contentSubType = INTERACTIVE_TYPE_AUDIO_RECORDING
const Interactive = AudioRecordingInteractive
const defaultValues = {}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const AudioRecording = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    captionAllowed
  />
)

const AudioRecordingWithContent = withContent(AudioRecording, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default AudioRecordingWithContent

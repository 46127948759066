import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
} from 'core/consts'
import { arraySequence, map } from 'fp/arrays'
import { generateId } from 'fp/utils'
import withContent from 'hoc/withContent'
import MultipleChoiceInteractive from 'hss/sections/contentBlocks/interactives/MultipleChoice'
import { compose } from 'redux'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_MULTIPLE_CHOICE
const Interactive = MultipleChoiceInteractive

const newAnswerChoice = () => ({
  id: generateId(),
  label: '',
  explanation: '',
  isCorrect: false,
})

const defaultValues = {
  questionHtml: '',
  allowMultipleSelection: false,
  answerChoices: compose(map(newAnswerChoice), arraySequence)(4),
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const MultipleChoice = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-MultipleChoice"
    standardsAllowed>
    <Content newAnswerChoice={newAnswerChoice} />
  </Form>
)

const MultipleChoiceWithContent = withContent(MultipleChoice, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default MultipleChoiceWithContent

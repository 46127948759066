import { FormIslandProvider } from 'common/formControls/Form/FormIsland'
import { get, set } from 'fp/objects'
import { prefix, suffix, wrap } from 'fp/strings'
import { throttle } from 'fp/utils'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
  labels,
} from 'hss/ContentBuilder/consts'
import { useCallback, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { compose } from 'redux'
import Feature from './Feature'
import { availableEchoFeatures, mutexFeatures } from './utils'

const responseLabels = {
  [ECHO_FEATURE_BLASTY_BLAST]: 'Blast',
  [ECHO_FEATURE_PICTURE_THIS]: 'Picture This',
  [ECHO_FEATURE_TALK_BACK]: 'Talk Back',
}

const features = availableEchoFeatures.map(key => ({
  key,
  label: labels[key],
  name: prefix('features.')(key),
}))

const Features = () => {
  const respondFeature = useWatch({ name: 'responseType' })
  const [expandedSection, setExpandedSection] = useState('')

  const responseLabeler = feature =>
    compose(
      label => set('label', label)(feature),
      suffix(wrap(' (', ')')(responseLabels[respondFeature])),
      get('label'),
    )(feature)

  const handleExpandSection = useCallback(feature => {
    const expandSection = throttle(100, setExpandedSection)
    expandSection(feature)
  }, [])

  return features
    .filter(({ key }) => key === respondFeature || !mutexFeatures.includes(key))
    .map(feature =>
      feature.key === respondFeature ? responseLabeler(feature) : feature,
    )
    .map(({ key, label, name }) => (
      <FormIslandProvider key={key}>
        <Feature
          expanded={key === expandedSection}
          feature={key}
          label={label}
          name={name}
          onExpandSection={handleExpandSection}
        />
      </FormIslandProvider>
    ))
}

export default Features

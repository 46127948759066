import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFProficienciesPicker from 'common/formControls/selects/HFProficienciesPicker'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import { CONTENT_TYPE_SCAFFOLD } from 'core/consts'
import { contentTypeShape } from 'core/shapes'
import Content from 'hss/ContentBuilder/interactives/Form/Content'
import WrapMode from 'hss/ContentBuilder/interactives/Form/WrapMode'
import Divider from 'hss/sections/settings/Divider'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import BlockVariantPicker from '../../BlockVariantPicker'
import ScaffoldAssociationPicker from '../../ScaffoldAssociationPicker'

const AttributesPanel = ({ contentType, currentContentType }) => {
  const isScaffold = contentType === CONTENT_TYPE_SCAFFOLD

  const { setValue } = useFormContext()
  const [float, fullWidth, standalone, variant] = useWatch({
    name: ['float', 'fullWidth', 'standalone', 'variant'],
  })

  const floatIsSet = ['left', 'right'].includes(float)
  const floatIsSupported = variant === 'callout-question'

  const handleScaffoldAssociationChange = newValue => {
    setValue('standalone', newValue, { shouldDirty: true })
  }

  useEffect(() => {
    if (floatIsSet && fullWidth) {
      setValue('fullWidth', false, { shouldDirty: true })
    }
  }, [floatIsSet, fullWidth, setValue])

  return (
    <ContainedFormContent>
      <Content
        assetCodeRequired={false}
        captionAllowed={false}
        currentContentType={currentContentType}
        framelessAllowed={false}
        interactiveInstructionsAllowed={false}
        omitAssetCode
        standardsAllowed={!isScaffold}
        studentInstructionsAllowed={false}
        vocabPhrasesAllowed={false}>
        {currentContentType === 'se' && !isScaffold ? (
          <>
            <HeadlineStyleOffset offset={4}>
              <BlockVariantPicker />

              <HFAssetUploader
                label="Background Image"
                name="uploadsMap.image"
                uploadType="image"
              />

              {!!floatIsSupported && (
                <>
                  <WrapMode />
                  <Divider />
                </>
              )}
            </HeadlineStyleOffset>

            <HeadlineStyleOffset offset={4}>
              <HFAssetUploader
                label="Audio File"
                name="uploadsMap.audio"
                uploadType="audio"
              />
            </HeadlineStyleOffset>
          </>
        ) : null}

        {Boolean(isScaffold) && (
          <HFProficienciesPicker
            name="proficiencyIds"
            required
          />
        )}

        {Boolean(isScaffold) && (
          <ScaffoldAssociationPicker
            onChange={handleScaffoldAssociationChange}
            style={{ marginBottom: 24 }}
            value={standalone}
          />
        )}
      </Content>
    </ContainedFormContent>
  )
}

AttributesPanel.propTypes = {
  contentType: contentTypeShape.isRequired,
  currentContentType: PropTypes.oneOf(['se', 'te']).isRequired,
}

export default AttributesPanel

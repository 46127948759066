import StringList from 'common/formControls/lists/StringList'
import Headline from 'common/text/Headline'
import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_POLL } from 'core/consts'
import { get, set } from 'fp/objects'
import withContent from 'hoc/withContent'
import PollInteractive from 'hss/sections/contentBlocks/interactives/Poll'
import Form from './Form'
import PreviewButton from './Form/Preview/PreviewButton'
import QuestionPrompt from './Form/QuestionPrompt'

const contentSubType = INTERACTIVE_TYPE_POLL
const Interactive = PollInteractive
const defaultValues = {
  prompt: '',
  options: [
    { id: 1, label: '' },
    { id: 2, label: '' },
  ],
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const Poll = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-Poll"
    standardsAllowed>
    <QuestionPrompt name="prompt" />

    <Headline
      hr
      mt={2}
      title="Answers">
      <StringList
        addButtonLabel="Add answer"
        getter={get('label')}
        label=""
        maximumItems={10}
        minimumItems={2}
        name="options"
        setter={(item, value) => set('label', value)(item)}
      />
    </Headline>
  </Form>
)

const PollWithContent = withContent(Poll, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default PollWithContent

import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import HFSelect from 'common/formControls/selects/HFSelect'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
} from 'hss/ContentBuilder/consts'
import { icons, mutexFeatures } from './utils'

const labels = {
  [ECHO_FEATURE_BLASTY_BLAST]: 'Blast (text)',
  [ECHO_FEATURE_PICTURE_THIS]: 'Picture This (image)',
  [ECHO_FEATURE_TALK_BACK]: 'Talk Back (audio)',
}

const ResponseType = () => (
  <HFSelect
    helperText={'Changing this will alter the contents of the "Respond" tab'}
    label="Response Type"
    name="responseType"
    required
    sx={{
      marginTop: 6,
      '.MuiSelect-select': { display: 'inline-flex' },
      '.MuiListItemIcon-root': { minWidth: 32 },
    }}>
    {mutexFeatures.map(key => (
      <MenuItem
        key={key}
        value={key}>
        <ListItemIcon>{icons[key]}</ListItemIcon>
        {labels[key]}
      </MenuItem>
    ))}
  </HFSelect>
)

export default ResponseType

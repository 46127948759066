import StringList from 'common/formControls/lists/StringList'
import { get, set } from 'fp/objects'
import InteractiveInstructions from './InteractiveInstructions'
import QuestionPrompt from './QuestionPrompt'
import StudentInstructions from './StudentInstructions'
import { featurePaneShape } from './utils'

const FeaturePoll = ({ disabled, feature }) => (
  <>
    <InteractiveInstructions
      disabled={disabled}
      feature={feature}
    />

    <QuestionPrompt
      disabled={disabled}
      feature={feature}
    />

    <StudentInstructions
      disabled={disabled}
      feature={feature}
    />

    <br />

    <StringList
      addButtonLabel="Add answer"
      disabled={disabled}
      getter={get('label')}
      label="Answers"
      maximumItems={10}
      minimumItems={disabled ? 0 : 2}
      name={`${feature}-data.options`}
      setter={(item, value) => set('label', value)(item)}
    />

    <hr />
  </>
)

FeaturePoll.propTypes = featurePaneShape

export default FeaturePoll

import { second } from 'fp/arrays'
import { get } from 'fp/objects'
import {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TALK_BACK,
  ECHO_FEATURE_TOP_TEN,
} from 'hss/ContentBuilder/consts'
import AwardFilled from 'hss/images/nav/award-filled.svg'
import CameraFilled from 'hss/images/nav/camera-filled.svg'
import GlobeFilled from 'hss/images/nav/globe-filled.svg'
import MessageSquareFilled from 'hss/images/nav/message-square-filled.svg'
import MicFilled from 'hss/images/nav/mic-filled.svg'
import PieChartFilled from 'hss/images/nav/pie-chart-filled.svg'
import StarFilled from 'hss/images/nav/star-filled.svg'
import PropTypes from 'prop-types'
import {
  Award,
  Camera,
  Globe,
  MessageSquare,
  Mic,
  PieChart,
  Star,
} from 'react-feather'
import { compose } from 'redux'

export const availableEchoFeatures = [
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TOP_TEN,

  // leave these three last
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
]

export const featuresOnlyAvailableOnceSubmitted = [
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TOP_TEN,
]

export const featuresWithAssetCodes = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
]

export const featuresWithStandards = [
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
]

export const featuresWithVocabulary = [
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
]

export const filledIcons = {
  [ECHO_FEATURE_ACT]: <GlobeFilled data-filled="true" />,
  [ECHO_FEATURE_BLASTY_BLAST]: <MessageSquareFilled data-filled="true" />,
  [ECHO_FEATURE_PICTURE_THIS]: <CameraFilled data-filled="true" />,
  [ECHO_FEATURE_POLL]: <PieChartFilled data-filled="true" />,
  [ECHO_FEATURE_RATE]: <StarFilled data-filled="true" />,
  [ECHO_FEATURE_TALK_BACK]: <MicFilled data-filled="true" />,
  [ECHO_FEATURE_TOP_TEN]: <AwardFilled data-filled="true" />,
}

export const icons = {
  [ECHO_FEATURE_ACT]: <Globe data-filled="false" />,
  [ECHO_FEATURE_BLASTY_BLAST]: <MessageSquare data-filled="false" />,
  [ECHO_FEATURE_PICTURE_THIS]: <Camera data-filled="false" />,
  [ECHO_FEATURE_POLL]: <PieChart data-filled="false" />,
  [ECHO_FEATURE_RATE]: <Star data-filled="false" />,
  [ECHO_FEATURE_TALK_BACK]: <Mic data-filled="false" />,
  [ECHO_FEATURE_TOP_TEN]: <Award data-filled="false" />,
}

export const mutexFeatures = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_TALK_BACK,
]

export const featurePaneShape = {
  expanded: PropTypes.bool.isRequired,
  feature: PropTypes.oneOf(availableEchoFeatures),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onExpandSection: PropTypes.func.isRequired,
}

export const isEnabled = compose(get('enabled'), second)

import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_WORD_CLOUD,
} from 'core/consts'
import withContent from 'hoc/withContent'
import WordCloudInteractive from 'hss/sections/contentBlocks/interactives/WordCloud'
import { interactiveVariants } from '../consts'
import Form from './Form'
import PreviewButton from './Form/Preview/PreviewButton'
import QuestionPrompt from './Form/QuestionPrompt'

const contentSubType = INTERACTIVE_TYPE_WORD_CLOUD
const Interactive = WordCloudInteractive
const defaultValues = {
  prompt: '',
  numPhrases: 1,
  variant: 'normal',
}

const WordCloud = formProps => {
  const previewButton = props => (
    <PreviewButton
      additionalInteractiveProps={{ words: [] }}
      {...{ Interactive, ...props }}
    />
  )

  return (
    <Form
      {...{ contentSubType, defaultValues, formProps, previewButton }}
      name="Interactive-WordCloud"
      standardsAllowed
      variants={{
        [interactiveVariants.NORMAL]: {
          displayName: 'Normal',
        },
        [interactiveVariants.WORD_CLOUD_CHOOSE_VOCAB_WORDS]: {
          displayName: 'Choose from Vocabulary Words',
          defaults: {
            numPhrases: 3,
            prompt:
              'Which terms from this list are you most interested in learning about?',
          },
        },
      }}>
      <HFNumberField
        label="Number of Phrases"
        name="numPhrases"
        required
      />

      <QuestionPrompt name="prompt" />
    </Form>
  )
}

const WordCloudWithContent = withContent(WordCloud, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default WordCloudWithContent

import {
  SCORING_MODE_CHART_COLUMN,
  SCORING_MODE_CHART_EXACT,
  SCORING_MODE_CHART_ROW,
  SCORING_MODE_CHART_ROW_COMPLETE,
  SCORING_MODE_EVAL_RANKING_EXACT,
  SCORING_MODE_GNS_GROUP,
  SCORING_MODE_GNS_GROUP_AND_SORT,
  SCORING_MODE_GNS_SORT_GIVEN_GROUPS,
  SCORING_MODE_IMAGE_TITLE_DRAG,
  SCORING_MODE_MULTICHOICE_ALL,
  SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED,
  SCORING_MODE_MULTICHOICE_WEIGHTED,
  SCORING_MODE_NONE,
  SCORING_MODE_RUBRIC,
} from 'hss/ContentBuilder/consts'

export const SCORING_MODE_META = {
  [SCORING_MODE_CHART_COLUMN]: {
    label: 'Column',
    helperText:
      'Partial credit is given for each column if the column has correct input values and all items placed by a student belong to that column',
  },
  [SCORING_MODE_CHART_EXACT]: {
    label: 'Exact',
    helperText:
      'Partial credit is given for each input that has correct value and for each item placed in correct cell by row and column',
  },
  [SCORING_MODE_CHART_ROW]: {
    label: 'Row',
    helperText:
      'Partial credit is given for each row if the row has correct input values and all items placed by a student belong to that row',
  },
  [SCORING_MODE_CHART_ROW_COMPLETE]: {
    label: 'Row Complete',
    helperText:
      'Partial credit is given for each row if all items placed by a student belong to same row, in the correct column. Vertical ordering of the rows does not matter',
  },
  [SCORING_MODE_EVAL_RANKING_EXACT]: {
    label: 'Exact',
    helperText: 'Point for each in the correct position',
  },

  [SCORING_MODE_GNS_GROUP]: {
    label: 'Group',
    helperText: 'Point for each option placed in the correct group',
  },
  [SCORING_MODE_GNS_GROUP_AND_SORT]: {
    label: 'Group and sort',
    helperText:
      'Point for each option placed in the correct position and correct group',
  },
  [SCORING_MODE_GNS_SORT_GIVEN_GROUPS]: {
    label: 'Sort, provided groups',
    helperText: 'Point for each option placed in the correct position',
  },
  [SCORING_MODE_IMAGE_TITLE_DRAG]: {
    label: 'Exact',
    helperText: 'Point for each item dragged to the correct position',
  },
  [SCORING_MODE_MULTICHOICE_ALL]: {
    label: 'Exact',
    helperText:
      'Point if every correct is checked, and every incorrect is not checked',
  },
  [SCORING_MODE_MULTICHOICE_CORRECT_WEIGHTED]: {
    label: 'Weighted (correct only)',
    helperText:
      'Partial credit for each correct checked, 0 if any incorrect checked',
  },
  [SCORING_MODE_MULTICHOICE_WEIGHTED]: {
    label: 'Weighted',
    helperText:
      'Partial credit for each correct checked and each incorrect unchecked',
  },
  [SCORING_MODE_NONE]: {
    label: 'None',
    helperText:
      'Not scored, purely for informational feedback to teacher/peers',
  },
  [SCORING_MODE_RUBRIC]: {
    label: 'Rubric',
    helperText: 'Teacher scores manually according to a rubric',
  },
}

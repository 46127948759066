/*

There's some tricky business here to be aware of.  When creating an interactive,
there are potentially two <forms />s. There's the main form for your builder, and
then the preview dialog will likely have a second one, depending on if it needs
to collect data from the user. One thing we cannot do is nest forms.

The Preview button needs to be inside the main form, but since it's responsible
for displaying the preview dialog, that would mean that the preview form would
end up nested within the main one.

Portalling the dialog outside the form doesn't work because even though it would
be located elsewhere in the DOM, hook-form still sees it as existing within its
provider and thus still being nested. The solution then is to place the preview
button and its dialog outside the main form and then portal the preview 𝘣𝘶𝘵𝘵𝘰𝘯
back into the form.

That solves the nesting issue, but there's still the problem of getting the values
out of the main form and into the preview one. To accomplish this there is a
content provider named BuilderProvider that wraps both forms and acts as a shuttle
for the data. When the user edits a form field, there's a watch within a component
named Content that picks up those changes and stores them in the BuilderProvider.
Then when the preview dialog is ready to be shown, the values are pulled from the
provider and presented to the dialog, which then passes it down to any forms
contained within.

┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃                                                                         ┃
┃                             BuilderProvider                             ┃
┃                                                                         ┃
┃    ┌───────────────────────┐               ┌───────────────────────┐    ┃
┃    │                       │               │                       │    ┃
┃    │      Edit Form        │     ----->    │     Preview Form      │    ┃
┃    │                       │     ----->    │      (in Dialog)      │    ┃
┃    │                       │     ----->    │                       │    ┃
┃    │                       │               │                       │    ┃
┃    │  ╭┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╮  │               │                       │    ┃
┃    │  ┆  PreviewButton  ┆  │               │                       │    ┃
┃    │  ┆   (portalled)   ┆  │               │ <---- PreviewButton   │    ┃
┃    │  ╰┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╯  │               │                       │    ┃
┃    │                       │               │                       │    ┃
┃    └───────────────────────┘               └───────────────────────┘    ┃
┃                                                                         ┃
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛

*/

import Portal from '@mui/material/Portal'
import BaseForm from 'common/formControls/Form'
import ErrorRollup from 'common/formControls/Form/ErrorRollup'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import {
  CONTENT_STATE_DRAFT,
  CONTENT_STATE_PUBLISHED,
  CONTENT_TYPE_RUBRIC,
  MANUAL_SUBMITTABLE_INTERACTIVE_DEFAULTS,
  TERRITORY_SELECTION_TYPE_EVERYONE,
} from 'core/consts'
import { componentShape } from 'core/shapes'
import { first } from 'fp/arrays'
import { increment } from 'fp/numbers'
import { set } from 'fp/objects'
import { identity } from 'fp/utils'
import {
  SCORING_MODES,
  SCORING_MODE_NONE,
  builderContentSubTypesShape,
  framelessByDefault,
} from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { contentBuilderUrl } from 'routing/consts'
import { formPropsShape, pullFormValues } from '../../utils'
import Content from './Content'
import ContentHeadline from './ContentHeadline'
import ContentStatePicker from './ContentStatePicker'
import FormActions from './FormActions'
import WrapMode from './WrapMode'
import { BuilderProvider } from './builderContext'

const Form = props => {
  const {
    assetCodeRequired = true,
    captionAllowed = false,
    captionOnSideAllowed = true,
    captionPosition = 'bottom',
    captionRequired = false,
    changingContentStateAllowed = true,
    children,
    contentSubType,
    defaultValues = {},
    floatSupported = false,
    formProps,
    formProps: { content },
    framelessAllowed = true,
    inputStyle,
    interactiveInstructionsAllowed = true,
    isDBI = false,
    nameHelperText,
    nameRequired = false,
    omitAssetCode = false,
    omitName = false,
    onBeforeSubmit,
    previewButton,
    showCaptionPosition = true,
    showVocabRollup = false,
    standardsAllowed = false,
    studentInstructionsAllowed = true,
    tagsAllowed = true,
    variants,
    vocabPhrasesAllowed = true,
    ...rest
  } = props

  const navigate = useNavigate()
  const previewButtonPortalRef = useRef(null)

  /**
   * Something super weird is happening here.
   *
   * previewButtonPortalRef gets passed into FormActions, which then applies it
   * to a span (located where we want the PreviewButton to appear). If you inspect
   * the ref within FormActions, you can see that `current` correctly switches
   * to the span, however this file (Form) never rerenders, so locally it looks
   * as if current is still null.  If we force a render then it all works fine.
   *
   * Long story story short, this useEffect and useState are here only to cause
   * the component to re-render whenever previewButtonPortalRef updates.
   */
  const [, setHack] = useState(1)
  // biome-ignore lint/correctness/useExhaustiveDependencies: I guess so?
  useEffect(() => {
    setHack(increment)
  }, [previewButtonPortalRef])

  /**
   * If we're not allowing the user to change the `contentState` and it currently
   * is set to be "draft", then here we bump it up to the "done" state.
   *
   * This is to account for curriculum and interactive forms that have no required
   * fields or that never need to to have their content state managed (blocks for
   * example), otherwise the `contentState` would be stuck at the default (draft).
   *
   * This type of form is currently being inferred from the `changingContentStateAllowed`
   * bool prop.  If ever we find an exception to this general case, then we can
   * introduce something like an `autoBumpContentStateOnSave` or similar.  Forms
   * that don't need this tracking can also hardcode the `contentState` to be
   * something other than draft as an alternative to this mechanism.
   *
   * `handleOnBeforeSubmit` receives the full state that is about to be submitted.
   * It must always return the intended state/payload.
   */
  const handleOnBeforeSubmit = useCallback(
    data => {
      const maybeSetContentState =
        !changingContentStateAllowed &&
        data.contentState === CONTENT_STATE_DRAFT
          ? set('contentState', CONTENT_STATE_PUBLISHED)
          : identity

      return compose(maybeSetContentState, onBeforeSubmit || identity)(data)
    },
    [changingContentStateAllowed, onBeforeSubmit],
  )

  const onSuccess = useCallback(
    ({ response }) => {
      const editLibraryItemUrlBuilder = item =>
        item.contentType === CONTENT_TYPE_RUBRIC
          ? `${contentBuilderUrl}/${item.contentType}/${item.id}`
          : `${contentBuilderUrl}/interactive/${item.contentSubType}/${item.id}`

      navigate(editLibraryItemUrlBuilder(response))
    },
    [navigate],
  )

  return (
    <BuilderProvider {...{ changingContentStateAllowed }}>
      <ContentHeadline {...{ ...formProps, contentSubType, isDBI }}>
        <BaseForm
          actionType={actionTypes.CONTENT_SAVE}
          defaultValues={{
            academicVocabPhraseIds: [],
            academicVocabPhrases: [],
            allowSubmission:
              MANUAL_SUBMITTABLE_INTERACTIVE_DEFAULTS.includes(contentSubType),
            applicationStandardIds: [],
            applicationStandards: [],
            contentSubType,
            contentVocabPhraseIds: [],
            contentVocabPhrases: [],
            footnoteVocabPhraseIds: [],
            footnoteVocabPhrases: [],
            frameless: framelessByDefault.includes(contentSubType),
            instructionStandardIds: [],
            instructionStandards: [],
            'scoring.allOrNothing': false,
            'scoring.maxScore': 0,
            'scoring.mode':
              first(SCORING_MODES[contentSubType]) || SCORING_MODE_NONE,
            tagIds: [],
            tags: [],
            territorySelections: [],
            territorySelectionType: TERRITORY_SELECTION_TYPE_EVERYONE,
            vocabContentIds: [],
            vocabContent: [],
            ...(floatSupported ? { float: 'none' } : null),
            ...defaultValues,
            ...pullFormValues(formProps),
          }}
          margin="normal"
          onBeforeSubmit={handleOnBeforeSubmit}
          onSuccess={onSuccess}
          preventEnterSubmits
          variant="outlined"
          {...rest}>
          <HeadlineStyleOffset offset={4}>
            <Content
              {...{
                assetCodeRequired,
                captionAllowed,
                captionOnSideAllowed,
                captionPosition,
                showCaptionPosition,
                captionRequired,
                content,
                contentSubType,
                framelessAllowed,
                inputStyle,
                interactiveInstructionsAllowed,
                nameHelperText,
                nameRequired,
                omitAssetCode,
                omitName,
                showVocabRollup,
                standardsAllowed,
                studentInstructionsAllowed,
                tagsAllowed,
                variants,
                vocabPhrasesAllowed,
              }}>
              {children}
            </Content>

            {Boolean(floatSupported) && <WrapMode />}

            {Boolean(changingContentStateAllowed) && <ContentStatePicker />}
          </HeadlineStyleOffset>

          <ErrorRollup />

          <FormActions ref={previewButtonPortalRef} />
        </BaseForm>

        {Boolean(previewButton) && (
          <Portal container={previewButtonPortalRef.current}>
            {previewButton()}
          </Portal>
        )}
      </ContentHeadline>
    </BuilderProvider>
  )
}

Form.propTypes = {
  assetCodeRequired: PropTypes.bool,
  captionAllowed: PropTypes.bool,
  captionOnSideAllowed: PropTypes.bool,
  captionPosition: PropTypes.oneOf(['left', 'bottom', 'right']),
  showCaptionPosition: PropTypes.bool,
  captionRequired: PropTypes.bool,
  changingContentStateAllowed: PropTypes.bool,
  children: componentShape,
  contentSubType: builderContentSubTypesShape,
  defaultValues: PropTypes.object,
  floatSupported: PropTypes.bool,
  formProps: formPropsShape.isRequired,
  framelessAllowed: PropTypes.bool,
  inputStyle: PropTypes.object,
  interactiveInstructionsAllowed: PropTypes.bool,
  isDBI: PropTypes.bool,
  nameHelperText: PropTypes.node,
  nameRequired: PropTypes.bool,
  omitAssetCode: PropTypes.bool,
  omitName: PropTypes.bool,
  onBeforeSubmit: PropTypes.func,
  previewButton: PropTypes.func,
  showVocabRollup: PropTypes.bool,
  standardsAllowed: PropTypes.bool,
  studentInstructionsAllowed: PropTypes.bool,
  tagsAllowed: PropTypes.bool,
  variants: PropTypes.object,
  vocabPhrasesAllowed: PropTypes.bool,
}

export default Form

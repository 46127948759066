import MenuItem from '@mui/material/MenuItem'
import HFSelect from 'common/formControls/selects/HFSelect'
import PropTypes from 'prop-types'
import { rem } from 'styling/theming/base/mixins'
import { availableEchoFeatures } from './utils'

const CharacterLimit = ({ disabled, feature }) => (
  <HFSelect
    disabled={disabled}
    label="Character Limit"
    name={`${feature}-data.characterLimit`}
    style={{ marginTop: rem(5) }}>
    <MenuItem value={150}>150</MenuItem>
    <MenuItem value={300}>300</MenuItem>
  </HFSelect>
)

CharacterLimit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  feature: PropTypes.oneOf(availableEchoFeatures).isRequired,
}

export default CharacterLimit

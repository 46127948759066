import { CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_IMAGE } from 'core/consts'
import withContent from 'hoc/withContent'
import ImageInteractive from 'hss/sections/contentBlocks/interactives/Image'
import { useState } from 'react'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_IMAGE
const Interactive = ImageInteractive
const defaultValues = {
  captionPosition: 'right',
  allowAnnotations: true,
  allowPanAndZoom: true,
  imageAltText: '',
  includeSwatch: false,
  noGutters: false,
  scrollHorizontally: false,
  sensitive: false,
  sensitiveWarning: 'This photo may show graphic or violent content.',
  uploadsMap: {},
}

const additionalInteractiveProps = {
  contentId: 'previewing',
  forceLongTextVisible: true,
}

const previewButton = props => (
  <PreviewButton {...{ additionalInteractiveProps, Interactive, ...props }} />
)

const Image = formProps => {
  const [captionOnSideAllowed, setCaptionOnSideAllowed] = useState(true)
  return (
    <Form
      {...{
        captionOnSideAllowed,
        contentSubType,
        defaultValues,
        formProps,
        previewButton,
      }}
      captionAllowed
      floatSupported
      interactiveInstructionsAllowed={false}
      name="Interactive-Image"
      showCaptionPosition
      studentInstructionsAllowed={false}>
      <Content setCaptionOnSideAllowed={setCaptionOnSideAllowed} />
    </Form>
  )
}

const ImageWithContent = withContent(Image, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default ImageWithContent

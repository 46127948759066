import Callout from 'common/Draggable/Callout'
import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import HFAssetUploader from 'common/formControls/selects/AssetUploader/HFAssetUploader'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import QuestionPrompt from '../Form/QuestionPrompt'
import Callouts from './Callouts'
import List from './Callouts/List'

const Content = () => (
  <>
    <ContainedFormContent>
      <QuestionPrompt name="prompt" />

      <HFAssetUploader
        label="Backdrop Image"
        name="uploadsMap.backdrop"
        required
        uploadType="image"
      />

      <HFSwitch
        label="Dark Backdrop Image"
        name="darkBackdropImage"
      />
    </ContainedFormContent>

    <Callouts
      dropTargets={['CALLOUT-ARROW', 'CALLOUT-LABEL']}
      ItemRenderer={Callout}
      ListRenderer={List}
      name="callouts"
    />
  </>
)

export default Content

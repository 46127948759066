import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import HFHorizontalRadioGroup from 'common/formControls/switches/HFHorizontalRadioGroup'
import { CONTENT_STATE_DRAFT, CONTENT_STATE_PUBLISHED } from 'core/consts'
import { contentStateLabels } from 'hss/ContentBuilder/consts'
import { useContext, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { rem } from 'styling/theming/base/mixins'

const contentStates = [
  {
    id: 'd',
    label: contentStateLabels[CONTENT_STATE_DRAFT],
    value: CONTENT_STATE_DRAFT,
  },
  {
    id: 'p',
    label: contentStateLabels[CONTENT_STATE_PUBLISHED],
    value: CONTENT_STATE_PUBLISHED,
  },
]

const ContentStatePicker = () => {
  const { setRequiredFieldsAreMandatory } = useContext(additionalContext)
  const contentState = useWatch({ name: 'contentState' })

  useEffect(() => {
    setRequiredFieldsAreMandatory(contentState === CONTENT_STATE_PUBLISHED)
  }, [contentState, setRequiredFieldsAreMandatory])

  return (
    <ContainedFormContent>
      <HFHorizontalRadioGroup
        formLabelProps={{ className: null }}
        helperText="Only 'Asset Code' is required for drafts.  To switch to the published state, all required fields need to be filled out.  These are denoted with an asterisk (*)"
        label="&nbsp;Content state&nbsp;"
        name="contentState"
        options={contentStates}
        style={{ margin: rem(2, 0, 1) }}
      />
    </ContainedFormContent>
  )
}

export default ContentStatePicker

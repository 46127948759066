import DeleteIcon from '@mui/icons-material/DeleteForever'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { itemRendererProps } from 'common/formControls/lists/AdvancedList'
import TE from 'common/indicators/TE'
import SaveFirstLink from 'common/navigation/links/SaveFirstLink'
import { CONTENT_TYPE_SECTION, CONTENT_TYPE_UNIT } from 'core/consts'
import { filter, first, map } from 'fp/arrays'
import { equals, get, notEquals } from 'fp/objects'
import { capitalize, isEmptyString } from 'fp/strings'
import { fallbackTo, matches, pipe } from 'fp/utils'
import withConfirm from 'hoc/withConfirm'
import useContent from 'hooks/useContent'
import PreviewContentHtml from 'hss/ContentViewer/Chapter/Block/PreviewContentHtml'
import { supportedBlockVariants } from 'hss/ContentViewer/Chapter/Block/Wrapper'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { compose } from 'redux'
import { labels } from '../consts'
import { buildContentUrl } from '../utils'
import ChildListTags from './ChildListTags'
import ChildListTerritorialTags from './ChildListTerritorialTags'
import { availableEchoFeatures } from './Echo/utils'
import { unitContentSubTypes } from './UnitContentSubTypePicker'

const ConfirmIconButton = withConfirm(IconButton)

const rollupTypeRegExp = /([^-]+)-rollup/

const ChildListItemRenderer = forwardRef(
  (
    {
      allowDeletion,
      contentType: parentContentType,
      disabled,
      index,
      removeItem,
      item,
      tabbed,
    },
    ref,
  ) => {
    const { contentType, id: contentId, itemTeacherEdition } = item
    const [previewContent, setPreviewContent] = useState(null)
    const observer = useRef(null)
    const content = useContent({ contentType, contentId }) || {}
    const {
      assetCode,
      contentSubType,
      data = {},
      name,
      teacherEdition: contentTeacherEdition,
    } = content
    const isRollup = contentSubType?.endsWith('-rollup')
    const isEchoFeature = availableEchoFeatures.includes(contentSubType)

    const teacherEdition = itemTeacherEdition || contentTeacherEdition
    const label = useMemo(() => {
      let result = name || assetCode || item.name
      const hasName = Boolean(result)

      if (isEchoFeature) return labels[contentSubType]

      result = `${result || ''} ${previewContent || (hasName ? '' : '[empty]')}`

      return result
    }, [
      assetCode,
      contentSubType,
      isEchoFeature,
      item.name,
      name,
      previewContent,
    ])

    const chipLabel = useMemo(
      // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: yup
      () => {
        if (tabbed) return 'Tab'

        const isSection = contentType === CONTENT_TYPE_SECTION
        const sectionPrefix =
          teacherEdition || item.teacherEdition ? <TE /> : 'SE'

        if (isSection && parentContentType === CONTENT_TYPE_SECTION)
          return 'DBI Section'

        if (isSection)
          return (
            <>
              {sectionPrefix} {labels[contentType]}
            </>
          )

        const [, rollupType] = contentSubType?.match(rollupTypeRegExp) || []
        if (rollupType) return capitalize(rollupType)

        const { label: variantLabel, shortLabel: variantShortLabel } =
          supportedBlockVariants
            .filter(compose(notEquals('none'), get('value')))
            .find(
              compose(equals(data.variant || item.data?.variant), get('value')),
            ) || {}

        if (contentType === CONTENT_TYPE_UNIT) {
          const unitContentSubTypeType = pipe(
            filter(matches('value', contentSubType)),
            map(get('title')),
            first,
            fallbackTo(labels[contentType]),
          )(unitContentSubTypes)

          return unitContentSubTypeType === 'Normal'
            ? 'Unit'
            : unitContentSubTypeType
        }

        return variantShortLabel || variantLabel
          ? variantShortLabel || variantLabel
          : labels[contentType]
      },
      [
        contentSubType,
        contentType,
        data.variant,
        item.data?.variant,
        item.teacherEdition,
        parentContentType,
        tabbed,
        teacherEdition,
      ],
    )
    const getContentLabel = useCallback(
      node => {
        let newPreviewContent = isRollup
          ? 'Contains no editable content'
          : String(node.textContent || node.innerText)
              .trim()
              .slice(0, 100)

        if (contentType === CONTENT_TYPE_UNIT) {
          /**
           * Unfortunately we don't have item.name here, only id and contentType.
           * This just means that we can't show the full unit name here.  Not the
           * end of the world and certainly not worth the extra api changes that
           * would be needed for shallow content items to also include their names.
           */
          newPreviewContent = `Content id #${item.id}`
        }
        return isEmptyString(newPreviewContent) ? null : newPreviewContent
      },
      [contentType, isRollup, item.id],
    )

    const previewRef = useCallback(
      node => {
        // disconnect the observer if the node is null
        if (!node) {
          if (observer.current) {
            observer.current.disconnect()
          }
          return
        }
        // set the initial preview content
        setPreviewContent(getContentLabel(node))

        observer.current = new MutationObserver(() => {
          setPreviewContent(getContentLabel(node))
        })

        observer.current.observe(node, {
          characterData: true,
          childList: true,
          subtree: true,
        })
      },

      [getContentLabel],
    )

    const handleDelete = useCallback(() => {
      removeItem(index)
    }, [index, removeItem])

    return (
      <ErrorBoundary moduleName="ChildList">
        <Box
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          key={`i_${index}`}
          ref={ref}>
          <Chip
            label={chipLabel}
            size="small"
            style={{ marginRight: 8, alignSelf: 'flex-start', marginTop: 8 }}
          />

          <Box
            marginTop={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap">
            {isRollup || isEchoFeature ? (
              <div style={{ width: '100%' }}>{label}</div>
            ) : (
              <SaveFirstLink
                className="child-link"
                disabled={disabled}
                to={buildContentUrl(item)}>
                {label}
              </SaveFirstLink>
            )}

            <ChildListTags content={content} />

            <ChildListTerritorialTags content={content} />
          </Box>

          {Boolean(allowDeletion && !isEchoFeature) && (
            <ConfirmIconButton
              aria-label={`Delete item ${index + 1}`}
              cancelLabel="No"
              confirmationMessage={
                <>
                  <p>Are you sure you wish to delete this item?</p>
                  <p>
                    <strong>NOTE:</strong> You'll need to press{' '}
                    <code>Save</code> at the bottom of the page for it to be
                    fully removed. You can press <code>Cancel</code> or reload
                    the page should you ever delete something by mistake. Most
                    changes are not committed until you press <code>Save</code>.
                  </p>
                </>
              }
              confirmationTitle="Confirm"
              confirmLabel="Yes, delete it"
              disabled={disabled}
              onClick={handleDelete}>
              <Icon>
                <DeleteIcon />
              </Icon>
            </ConfirmIconButton>
          )}

          <div
            ref={previewRef}
            style={{ display: 'none' }}>
            <PreviewContentHtml body={data?.body} />
          </div>
        </Box>
      </ErrorBoundary>
    )
  },
)

ChildListItemRenderer.propTypes = itemRendererProps

export default ChildListItemRenderer

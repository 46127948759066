import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
} from 'core/consts'
import withContent from 'hoc/withContent'
import ImageZoomyInteractive from 'hss/sections/contentBlocks/interactives/ImageZoomy'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_IMAGE_ZOOMY
const Interactive = ImageZoomyInteractive
const defaultValues = {
  imageAltText: '',
  uploadsMap: {},
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const ImageZoomy = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-ImageZoomy">
    <Content />
  </Form>
)

const ImageZoomyWithContent = withContent(ImageZoomy, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default ImageZoomyWithContent

import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
} from 'core/consts'
import withContent from 'hoc/withContent'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import EvaluationRankingInteractive from 'hss/sections/contentBlocks/interactives/EvaluationRanking'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import Content from './Content'

const contentSubType = INTERACTIVE_TYPE_EVALUATION_RANKING
const Interactive = EvaluationRankingInteractive
const defaultValues = {
  items: [],
  firstLabel: '',
  lastLabel: '',
  variant: 'normal',
}

const previewButton = props => <PreviewButton {...{ Interactive, ...props }} />

const EvaluationRanking = formProps => (
  <Form
    {...{
      contentSubType,
      defaultValues,
      formProps,
      previewButton,
    }}
    standardsAllowed
    variants={{
      [interactiveVariants.NORMAL]: {
        displayName: 'Normal',
      },
      [interactiveVariants.EVALUATION_RANKING_VOCAB_FAMILIARITY]: {
        displayName: 'Familiar & Unfamiliar Vocabulary Words',
        defaults: {
          prompt:
            'Rank the vocabulary words on a scale from most to least familiar to you.',
          firstLabel: 'Most Familiar',
          lastLabel: 'Least Familiar',
        },
      },
    }}>
    <Content />
  </Form>
)

const EvaluationRankingWithContent = withContent(EvaluationRanking, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default EvaluationRankingWithContent

import Warning from 'common/layout/Warning'
import { useFormContext } from 'react-hook-form'

const CorrectAnswerWarning = () => {
  const {
    formState: {
      errors: { answerChoices: answerChoicesError },
    },
  } = useFormContext()
  return answerChoicesError && <Warning>{answerChoicesError.message}</Warning>
}

export default CorrectAnswerWarning

import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import {
  CONTENT_TYPE_INTERACTIVE,
  INTERACTIVE_TYPE_LINEAR_SCALE,
} from 'core/consts'
import { arraySequence, findMaxValue } from 'fp/arrays'
import withContent from 'hoc/withContent'
import LinearScaleInteractive from 'hss/sections/contentBlocks/interactives/LinearScale'
import { useWatch } from 'react-hook-form'
import Form from '../Form'
import PreviewButton from '../Form/Preview/PreviewButton'
import QuestionPrompt from '../Form/QuestionPrompt'
import Marks from './Marks'

const contentSubType = INTERACTIVE_TYPE_LINEAR_SCALE
const Interactive = LinearScaleInteractive
const defaultValues = {
  initialDefaultValue: 5,
  question: '',
  marks: [
    { value: 1, label: 'Least Important' },
    { value: 10, label: 'Most Important' },
  ],
}

// this is for preview purposes only to show what the aggregated data might look like
const additionalInteractiveProps = {
  dataPoints: arraySequence(10).map(idx => ({
    id: idx + 1,
    label: `${idx + 1}`,
    votes: Math.floor(Math.random() * 10),
  })),
}

const previewButton = props => (
  <PreviewButton
    additionalInteractiveProps={additionalInteractiveProps}
    {...{ Interactive, ...props }}
  />
)

const LSNumberField = () => {
  const marks = useWatch({ name: 'marks' })

  return (
    <HFNumberField
      label="Default Value"
      max={Math.min(findMaxValue('value')(marks), 10)}
      min={1}
      name="initialDefaultValue"
      sx={{ mt: 6, width: 100, display: 'block' }}
    />
  )
}

const LinearScale = formProps => (
  <Form
    {...{ contentSubType, defaultValues, formProps, previewButton }}
    name="Interactive-LinearScale"
    standardsAllowed>
    <QuestionPrompt name="question" />

    <Marks name="marks" />
    <LSNumberField />
  </Form>
)

const LinearScaleWithContent = withContent(LinearScale, {
  contentType: CONTENT_TYPE_INTERACTIVE,
})

export default LinearScaleWithContent

import RadioGroup from '@mui/material/RadioGroup'
import Radio from 'common/formControls/switches/Radio'
import {
  TERRITORY_SELECTION_TYPE_EVERYONE,
  TERRITORY_SELECTION_TYPE_EXCLUDE,
  TERRITORY_SELECTION_TYPE_INCLUDE,
} from 'core/consts'
import { toInt } from 'fp/strings'
import { takeSecond } from 'fp/utils'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const SelectionType = ({ onChange, value }) => {
  const handleChange = compose(onChange, toInt, takeSecond)

  return (
    <RadioGroup
      label="Territory selection type"
      name="territorySelectionType"
      onChange={handleChange}
      value={value}>
      <Radio
        label="Visible to ALL"
        value={TERRITORY_SELECTION_TYPE_EVERYONE}
      />
      <Radio
        label="Available only to selected territories"
        value={TERRITORY_SELECTION_TYPE_INCLUDE}
      />
      <Radio
        label="Hidden from selected territories"
        value={TERRITORY_SELECTION_TYPE_EXCLUDE}
      />
    </RadioGroup>
  )
}

SelectionType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([
    TERRITORY_SELECTION_TYPE_EVERYONE,
    TERRITORY_SELECTION_TYPE_INCLUDE,
    TERRITORY_SELECTION_TYPE_EXCLUDE,
  ]).isRequired,
}

export default SelectionType

import type { Content, ContentType } from 'common/@types/custom'
import { CONTENT_STATE_DRAFT } from 'core/consts'
import { contentShape, contentTypeShape } from 'core/shapes'
import { omit } from 'fp/objects'
import PropTypes from 'prop-types'
import type { ComponentType } from 'react'
import { createElement } from 'react'
import { contentBuilderUrl } from 'routing/consts'

export const withEdit =
  (Component: ComponentType<{ editing: boolean }>) => () =>
    createElement(Component, { editing: true })

export const pullFormValues = ({
  content,
  contentType,
}: { content: Content; contentType: ContentType }) => ({
  assetCode: '',
  contentState: content?.contentState || CONTENT_STATE_DRAFT,
  contentType,
  ...omit('data')(content || {}),
  ...content?.data,
})

export const buildContentUrl = ({
  contentType,
  id,
}: { contentType: ContentType; id: string }) =>
  `${contentBuilderUrl}/curriculum/${contentType}/${id}`

export const formProps = {
  content: contentShape,
  contentType: contentTypeShape.isRequired,
  editing: PropTypes.bool,
}

export const formPropsShape = PropTypes.shape(formProps)

import MenuItem from '@mui/material/MenuItem'
import Select from 'common/formControls/selects/HFSelect'
import Slider from 'common/formControls/sliders/HFSlider'
import Checkbox from 'common/formControls/switches/HFCheckbox'
import HFJsonTextArea, {
  JSON_TYPE_ARRAY,
} from 'common/formControls/textInputs/HFJsonTextArea'

const Content = () => (
  <>
    <Slider
      label="Offset Radius"
      max={10}
      min={1}
      name="offsetRadius"
    />

    <Checkbox name="showNavigation" />

    <Select
      name="transition"
      required>
      <MenuItem value="gentle">Gentle</MenuItem>
      <MenuItem value="slow">Slow</MenuItem>
      <MenuItem value="wobbly">Wobbly</MenuItem>
      <MenuItem value="stiff">Stiff</MenuItem>
    </Select>

    <HFJsonTextArea
      jsonType={JSON_TYPE_ARRAY}
      name="slides"
      required
    />
  </>
)

export default Content

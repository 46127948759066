import { Box } from '@mui/material'
import ImageA11ySection from 'common/formControls/ImageA11ySection'
import HFAssetSelector from 'common/formControls/selects/AssetUploader/HFAssetSelector'
import { wrapCantoDirective } from 'common/formControls/selects/AssetUploader/utils'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import CollapsibleFormSection from 'common/layout/CollapsibleFormSection'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const Wrapper = ({ children }) => (
  <Box
    mb={1.5}
    mt={3.5}>
    {children}
  </Box>
)

Wrapper.propTypes = { children: componentShape }

const Content = ({ setCaptionOnSideAllowed }) => {
  const [includeSwatch, noGutters, scrollHorizontally, sensitive] = useWatch({
    name: ['includeSwatch', 'noGutters', 'scrollHorizontally', 'sensitive'],
  })

  const { setValue } = useFormContext()

  useEffect(() => {
    if (scrollHorizontally) {
      setValue('includeSwatch', false)
      setValue('noGutters', false)
    }
  }, [scrollHorizontally, setValue])

  useEffect(() => {
    if (includeSwatch) {
      setValue('noGutters', false)
    }
  }, [includeSwatch, setValue])

  useEffect(() => {
    if (scrollHorizontally || noGutters) {
      setValue('captionPosition', 'bottom')
      setCaptionOnSideAllowed(false)
    } else {
      setCaptionOnSideAllowed(true)
    }
  }, [noGutters, scrollHorizontally, setCaptionOnSideAllowed, setValue])

  const onCantoFields = fields => {
    Object.entries(fields).forEach(([key, value]) =>
      setValue(key, wrapCantoDirective(value), { shouldDirty: true }),
    )
  }

  return (
    <>
      <HFAssetSelector
        headlineHelperText="Ideal for larger-sized images, often used as banners. Usually cropped to fit a 16:9 ratio."
        label="Poster"
        name="uploadsMap.imagePoster"
        uploadType="image"
      />

      <HFAssetSelector
        headlineHelperText="Default size for most images and will auto-scale down unless overrides are manually provided. Usually un-cropped."
        label="Image"
        name="uploadsMap.image"
        onCantoFields={onCantoFields}
        required
        uploadType="image"
        variantLinkage={[
          {
            formField: 'uploadsMap.imageThumb',
            variant: 'thumb',
          },
        ]}
      />

      <HFAssetSelector
        headlineHelperText="Ideal for medium-sized images, often used inside cards or drag-and-drops. Usually cropped to fit a 4:3 ratio."
        label="Thumbnail"
        name="uploadsMap.imageThumb"
        required
        uploadType="image"
        uploadVariant="thumb"
      />

      <HFAssetSelector
        headlineHelperText="Ideal for the smallest-sized images, often used as icons. Usually cropped to fit a 1:1 ratio."
        label="Icon"
        name="uploadsMap.imageIcon"
        uploadType="image"
      />

      <Wrapper>
        <CollapsibleFormSection label="Narrative">
          <HFSwitch
            helperText="Use for wide images."
            label="Scroll horizontally"
            name="scrollHorizontally"
          />

          <HFSwitch
            disabled={Boolean(scrollHorizontally)}
            helperText="Displays a colored block over an edge of the image.  Color is determined by the containing subsection. (only applies when there are margins and a caption, and the caption is placed below the image)"
            label="Include accent color swatch"
            name="includeSwatch"
          />

          <HFSwitch
            disabled={Boolean(scrollHorizontally || includeSwatch)}
            helperText="Allows image to span the full width of the page, similar to a hero image."
            label="Display full width"
            name="noGutters"
          />

          <HFSwitch
            helperText="Initially hides the image and lets the user decide if they want to view it"
            label="Sensitive content"
            name="sensitive"
          />
        </CollapsibleFormSection>
      </Wrapper>

      {Boolean(sensitive) && (
        <HFTextField
          label="Sensitive Content Warning"
          name="sensitiveWarning"
        />
      )}

      <Wrapper>
        <ImageA11ySection altTextProps={{ required: false }} />
      </Wrapper>
    </>
  )
}

Content.propTypes = {
  setCaptionOnSideAllowed: PropTypes.func.isRequired,
}

export default Content
